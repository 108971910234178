import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const BannerList = Loadable(lazy(() => import("./BannerList")));
const AddBanner = Loadable(lazy(() => import("./AddBanner")));
const EditBanner = Loadable(lazy(() => import("./EditBanner")));

const BannerRoutes = [
  {
    path: "/banner-list",
    element: <BannerList />,
    auth: authRoles.guest,
  },

  {
    path: "/banner-list/add-banner",
    element: <AddBanner />,
    auth: authRoles.guest,
  },
  {
    path: "/banner-list/edit-banner/:id",
    element: <EditBanner />,
    auth: authRoles.guest,
  },
];

export default BannerRoutes;
