import { decryptData } from "app/helperFuntion/encryptDecrypt";
import useAuth from "app/hooks/useAuth";
import { LoginID } from "app/redux/reducers/VendoreLogin";
import { ResendOTP } from "otp-input-react";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resendOtp } from "services/web/Login";
import SliderIMG from "./Slider";
import { FaArrowLeft } from "react-icons/fa";
import { Login1, NewLogo3 } from "Assets";
// import "../../../../Assets/css/web.css";

const LoginOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otpValue, setOtpValue] = useState("");
  const [loader, setLoader] = useState(false);
  const { vendorlogin } = useAuth();

  const { phone } = useParams();

  const renderButton = (buttonProps) => {
    return (
      <Link {...buttonProps} className="me-2" style={{ color: "#FF5722" }}>
        {buttonProps.remainingTime !== 0
          ? `Resend in ${buttonProps.remainingTime} sec`
          : "Resend"}
      </Link>
    );
  };
  const renderTime = () => React.Fragment;

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (e.target.value === " ") {
      return false;
    } else {
      const postData = {
        otp: otpValue,
        phone_number: decryptData(phone),
        country_code: 91,
      };
      await vendorlogin(postData).then((res) => {
        setLoader(false);
        if (res?.status) {
          dispatch(LoginID(res?.data?._id));
          if (
            res?.data?.is_profile_completed &&
            Number(res.data.is_profile_completed) === 1
          ) {
            navigate("/vendor-dashboard");
            window.location.reload();
          } else if (
            res?.data?.is_approved === "APPROVED" &&
            res?.data?.turf_count === 1
          ) {
            navigate("/web-congrats");
            window.location.reload();
          } else {
            navigate("/web-profile");
            window.location.reload();
          }
        } else {
          toast.error("Please Enter Valid OTP");
          setLoader(false);
        }
      });
    }
  };

  const handleResend = async (e) => {
    let data = {
      country_code: 91,
      phone_number: decryptData(phone),
    };

    if (e === true) {
      await resendOtp(data)
        .then((res) => {
          if ((res.data.status = true)) {
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <>
      {/* <section className="login-wap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 py-4">
              <div className="login-image">
                <SliderIMG />
              </div>
            </div>

            <div className="col-md-5 d-flex align-items-center">
              <div className="login-box my-4">
                <div className="login-content">
                  <h3 className="logintext text-center">Verification Code</h3>
                  <p
                    className="mt-2 vendor-p"
                    style={{ color: "#33333399", textAlign: "center" }}
                  >
                    Please enter the OTP sent to your mobile number
                  </p>
                  <form
                    className="from-inline"
                    onSubmit={handleSubmit}
                    id="loginfrom"
                  >
                    <div className="row mt-4 justify-content-center">
                      <div className="col-md-10 w-100">
                        <div className="otp">
                          <OtpInput
                            name="otp"
                            value={otpValue}
                            onChange={setOtpValue}
                            numInputs={6}
                            className="inputStyle"
                            inputStyle={{
                              width: "50px",
                              textAlign: "center",
                              height: " 50px",
                              border: "1px solid #E0E0E0",
                              padding: " 0px",
                              margin: "0px 10px 20px 10px",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-10 justify-content-center w-100">
                        <div className="form-group">
                          <button type="submit" className="Login-button">
                            {(loader && (
                              <Spinner
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            )) ||
                              "SUBMIT"}
                          </button>
                        </div>
                      </div>

                      <div className="col-md-10 w-100">
                        <div className="d-flex justify-content-between mt-3">
                          <div>
                            <p className="vendor-p">
                              {" "}
                              Did not receive the OTP?
                            </p>
                          </div>

                          <div>
                            <ResendOTP
                              renderButton={renderButton}
                              renderTime={renderTime}
                              maxTime={30}
                              style={{ color: "white" }}
                              onResendClick={(e) => {
                                handleResend(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="login-top-content">
                <p onClick={() => handleNavigate()} className="cursor-pointer">
                  {/* <a href="/"> */}
                  <FaArrowLeft style={{ color: "#FF5722" }} /> Back to login
                  {/* </a>{" "} */}
                </p>
                <img src={NewLogo3} alt="login" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <div className="login-container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="login-container-text">
                      <h1>
                        login & manage <br /> customers!
                      </h1>
                      <p>
                        Access your account in seconds and take control of your
                        customer interactions with ease. Manage, track, and grow
                        your bussiness—all from one powerful dashboard!
                      </p>
                      <img src={Login1} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="login-container-form">
                      <span className="welcome">Welcome Back</span>
                      <h1>Verification Code</h1>
                      <p>Please enter the OTP sent to your mobile number</p>
                      <form onSubmit={handleSubmit} className="login-form">
                        <div className="row mt-4 justify-content-center">
                          <div className="col-md-10 w-100">
                            <div className="otp">
                              <OtpInput
                                name="otp"
                                value={otpValue}
                                onChange={setOtpValue}
                                numInputs={6}
                                className="inputStyle"
                                inputStyle={{
                                  width: "50px",
                                  textAlign: "center",
                                  height: " 50px",
                                  border: "1px solid #E0E0E0",
                                  borderRadius: "6px",
                                  padding: " 0px",
                                  margin: "0px 10px 20px 10px",
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <button type="submit" className="Login-button">
                              {(loader && (
                                <Spinner
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              )) ||
                                "SUBMIT"}
                            </button>
                          </div>

                          <div className="d-flex justify-content-between mt-3">
                            <p> Did not receive the OTP?</p>

                            <ResendOTP
                              renderButton={renderButton}
                              renderTime={renderTime}
                              maxTime={30}
                              style={{ color: "white" }}
                              onResendClick={(e) => {
                                handleResend(e);
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginOtp;
