import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const AdvertisementList = Loadable(lazy(() => import("./AdvertisementList")));
const AddAdvertisement = Loadable(lazy(() => import("./AddAdvertisement")));
const EditAdvertisement = Loadable(lazy(() => import("./EditAdvertisement")));
const AdvertisementDetail = Loadable(
  lazy(() => import("./AdvertisementDetail"))
);

const AdvertisementRoutes = [
  {
    path: "/advertisement-list",
    element: <AdvertisementList />,
    auth: authRoles.guest,
  },
  {
    path: "/advertisement-list/add-advertisement",
    element: <AddAdvertisement />,
    auth: authRoles.guest,
  },
  {
    path: "/advertisement-list/edit-advertisement/:id",
    element: <EditAdvertisement />,
    auth: authRoles.guest,
  },
  {
    path: "/advertisement-list/advertisement-detail/:id",
    element: <AdvertisementDetail />,
    auth: authRoles.guest,
  },
];

export default AdvertisementRoutes;
