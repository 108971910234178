import { createSlice } from "@reduxjs/toolkit";

export const ExtendAndAmend = createSlice({
  name: "Data",
  initialState: {
    data: "",
  },
  reducers: {
    setExtendData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setExtendData } = ExtendAndAmend.actions;

export default ExtendAndAmend.reducer;
