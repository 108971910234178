import {
  blank_mobile,
  blank_mobile1,
  blank_mobile2,
  blank_mobile3,
  blank_mobile4,
  blank_mobile5,
} from "Assets";
import { useEffect, useState } from "react";

const data = [
  { id: 1, img: blank_mobile1 },
  { id: 2, img: blank_mobile2 },
  { id: 3, img: blank_mobile3 },
  { id: 4, img: blank_mobile4 },
  { id: 5, img: blank_mobile5 },
];

const SliderContent = (props) => {
  return (
    <div className="sliderContent">
      <img src={props.img} alt="" />
    </div>
  );
};

const Slider = ({ activeSlide = 0, data }) => {
  const [activeSlideState, setActiveSlide] = useState(activeSlide);

  const next = () => {
    setActiveSlide((prev) => (prev + 1) % data.length);
  };

  useEffect(() => {
    const interval = setInterval(next, 3000);
    return () => clearInterval(interval);
  }, []);

  const getStyles = (index) => {
    const distance = (index - activeSlideState + data.length) % data.length;
    if (distance === 0)
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    if (distance === 1)
      return {
        opacity: 1,
        transform: "translateX(240px) translateZ(-400px) rotateY(-35deg)",
        zIndex: 9,
      };
    if (distance === 2)
      return {
        opacity: 1,
        transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 8,
      };
    if (distance === data.length - 1)
      return {
        opacity: 1,
        transform: "translateX(-240px) translateZ(-400px) rotateY(35deg)",
        zIndex: 9,
      };
    if (distance === data.length - 2)
      return {
        opacity: 1,
        transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
        zIndex: 8,
      };
    return {
      opacity: 0,
      transform: "translateX(0px) translateZ(-500px) rotateY(0deg)",
      zIndex: 7,
    };
  };

  return (
    <div className="slideC">
      {data.map((item, i) => (
        <div key={item.id} className="slide" style={{ ...getStyles(i) }}>
          <SliderContent {...item} />
        </div>
      ))}
      <div className="blank_img">
        <img src={blank_mobile} alt="" />
        <div className=""></div>
      </div>
    </div>
  );
};

export default function SliderIMG() {
  return (
    <div>
      <div className="center">
        <Slider data={data} activeSlide={2} />
      </div>
    </div>
  );
}
