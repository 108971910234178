import React from "react";

const ChildBanner = (props) => {
  return (
    <>
      <section className="innner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-content text-center">
                <h1 className="vendor-heading">{props.title} </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChildBanner;
