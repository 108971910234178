import { createSlice } from "@reduxjs/toolkit";

export const Otp = createSlice({
  name: "Otp",
  initialState: {
    otp: "",
  },
  reducers: {
    setLoginOtp: (state, action) => {
      state.otp = action.payload;
    },
  },
});

export const { setLoginOtp } = Otp.actions;

export default Otp.reducer;
