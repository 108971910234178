import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const PostList = Loadable(lazy(() => import("./PostList")));
const AddPost = Loadable(lazy(() => import("./AddPost")));
const EditPost = Loadable(lazy(() => import("./EditPost")));
const PostDetail = Loadable(lazy(() => import("./PostDetail")));

const PostRoutes = [
  {
    path: "/post-list",
    element: <PostList />,
    auth: authRoles.guest,
  },

  {
    path: "/post-list/add-post",
    element: <AddPost />,
    auth: authRoles.guest,
  },
  {
    path: "/post-list/edit-post/:id",
    element: <EditPost />,
    auth: authRoles.guest,
  },
  {
    path: "/post-list/view-post/:id",
    element: <PostDetail />,
    auth: authRoles.guest,
  },
];

export default PostRoutes;
