import { createSlice } from "@reduxjs/toolkit";

export const AvailableSlotsData = createSlice({
  name: "Data",
  initialState: {
    data: "",
  },
  reducers: {
    setAvailableData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setAvailableData } = AvailableSlotsData.actions;

export default AvailableSlotsData.reducer;
