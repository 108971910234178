import styled from "@emotion/styled";
import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
import { getAllMatchList } from "services/admin/Tournamet";
import { scoreFunctions } from "../Tournament/StartMatch/scoring/scoreFunctions";
import { inningObj } from "../Tournament/StartMatch/scoring/scoreHelper";
import { Helper } from "app/helperFuntion";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const filtersObj = {
  TODAY: () => {
    const todayDate = new Date();
    return {
      start_date: Helper.getOnlyDateStringFromDate(todayDate),
      end_date: Helper.getOnlyDateStringFromDate(todayDate),
    };
  },
  YESTERDAY: () => {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    return {
      start_date: Helper.getOnlyDateStringFromDate(todayDate),
      end_date: Helper.getOnlyDateStringFromDate(todayDate),
    };
  },
  THIS_WEEK: () => {
    const todayDate = new Date();
    const firstDayOfWeek = new Date(todayDate.setDate(0));
    const lastDayOfWeek = new Date(todayDate.setDate(todayDate.getDate() + 6));
    return {
      start_date: Helper.getOnlyDateStringFromDate(firstDayOfWeek),
      end_date: Helper.getOnlyDateStringFromDate(lastDayOfWeek),
    };
  },
  THIS_MONTH: () => {
    const todayDate = new Date();
    const firstDayOfMonth = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth() + 1,
      0
    );
    return {
      start_date: Helper.getOnlyDateStringFromDate(firstDayOfMonth),
      end_date: Helper.getOnlyDateStringFromDate(lastDayOfMonth),
    };
  },
  THIS_YEAR: () => {
    const todayDate = new Date();
    const firstDayOfYear = new Date(todayDate.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(todayDate.getFullYear(), 11, 31);
    return {
      start_date: Helper.getOnlyDateStringFromDate(firstDayOfYear),
      end_date: Helper.getOnlyDateStringFromDate(lastDayOfYear),
    };
  },
};

const MatchList = () => {
  const [loading, setLoading] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [dateRange, setDateRange] = useState({
    filter: "TODAY",
    ...filtersObj["TODAY"](),
  });
  const [selMatchType, setSelMatchType] = useState("LIVE");
  console.log(dateRange, "dateRange");
  let tabs = ["LIVE", "UPCOMING", "COMPLETED"];

  const handleChange = (event, newValue) => {
    getData(newValue, searchInput, filtersObj[dateRange.filter]());
    setSelMatchType(newValue);
  };

  const getData = async (matchType, search, datefilter) => {
    setLoading(true);
    setSearchInput(search);
    let params = { status: matchType };

    params.start_date = datefilter.start_date;
    params.end_date = datefilter.end_date;

    if (search) params.search = search;
    await getAllMatchList(params)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setMatchData(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(selMatchType, searchInput, dateRange);
  }, []);

  const getApproxOvers = (overs) => {
    return (
      (String(overs).split(".")[1] === "6" &&
        Number(String(overs).split(".")[0]) + 1) ||
      overs
    );
  };

  const getMatchPosition = (curmatch) => {
    let obj = false;
    if (curmatch) {
      let inning1;
      let inning2;
      if (curmatch?.super_over && curmatch.super_over.length > 0) {
        inning1 =
          curmatch.super_over[curmatch.super_over.length - 1].innings[0];
        inning2 =
          curmatch.super_over[curmatch.super_over.length - 1].innings[1];
      } else if (curmatch.innings) {
        inning1 =
          (curmatch.innings[0].first_bat && curmatch.innings[0]) ||
          curmatch.innings[1];
        inning2 =
          (curmatch.innings[0].first_bat && curmatch.innings[1]) ||
          curmatch.innings[0];
      } else {
        inning1 = inningObj;
        inning2 = inningObj;
      }
      obj = {
        inning1: { ...inning1 },
        inning2: { ...inning2 },
        outcome: curmatch.innings ? scoreFunctions.getOutCome(curmatch) : null,
      };
    }
    return obj;
  };

  const handleDateRange = (e) => {
    const { name, value } = e.target;
    if (name === "filter") {
      setDateRange({ filter: value });
      if (value !== "CUSTOM_DATE" && value !== "CUSTOM_RANGE_DATE") {
        getData(selMatchType, searchInput, filtersObj[value]());
      }
    } else if (name === "start_date" || name === "end_date") {
      setDateRange({ ...dateRange, [name]: value });
      let data = { ...dateRange, [name]: value };
      if (Object.values(data).length > 2) {
        getData(selMatchType, searchInput, data);
      }
    } else if (name === "date") {
      setDateRange({ filter: dateRange.filter, [name]: value });
      getData(selMatchType, searchInput, {
        start_date: value,
        end_date: value,
      });
    }
  };

  const getCurrentMatch = (item) => {
    console.log("item", item);
    if (item?.match) {
      // setRunningMatchData(item.match);
      // setMatchValue("matches_score");
      // getMatchRunning(item);
      // setCurMatchListData(item);
      // if (item.match?.super_over && item.match.super_over.length > 0) {
      //   let lastSuperOver =
      //     item.match.super_over[item.match.super_over.length - 1];
      //   setSubMatch(lastSuperOver);
      // } else {
      //   setSubMatch(item.match);
      // }
    }
  };

  const status = {
    LIVE: "Live",
    UPCOMING: "Upcoming",
    COMPLETED: "Completed",
  };

  return (
    <Container>
      <Grid container spacing={3} className="mb-3">
        <Grid
          item
          lg={
            dateRange.filter === "CUSTOM_RANGE_DATE"
              ? 3.7
              : dateRange.filter === "CUSTOM_DATE"
              ? 6
              : 8
          }
          md={6}
          sm={12}
          xs={12}
        >
          <h2 className="h2-chart pt-2">Matches</h2>
        </Grid>
        <Grid item lg={2} md={3} sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaSearch />
                </InputAdornment>
              ),
            }}
            placeholder={`Search`}
            variant="outlined"
            size="small"
            value={searchInput}
            onChange={(e) =>
              getData(
                selMatchType,
                e.target.value,
                filtersObj[dateRange.filter]()
              )
            }
          />
        </Grid>
        <Grid item lg={2} md={3} sm={12} xs={12}>
          <FormControl fullWidth size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              name="filter"
              value={dateRange?.filter}
              onChange={(e) => handleDateRange(e)}
            >
              <MenuItem value="TODAY">Today</MenuItem>
              <MenuItem value="YESTERDAY">Yesterday</MenuItem>
              <MenuItem value="THIS_WEEK">This Week</MenuItem>
              <MenuItem value="THIS_MONTH">This Month</MenuItem>
              <MenuItem value="THIS_YEAR">This Year</MenuItem>
              <MenuItem value="CUSTOM_DATE">Custom Date</MenuItem>
              <MenuItem value="CUSTOM_RANGE_DATE">Custom Range Date</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {dateRange.filter === "CUSTOM_DATE" && (
          <Grid item lg={2} md={3} sm={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Date"
              name="date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleDateRange(e)}
            />
          </Grid>
        )}
        {dateRange.filter === "CUSTOM_RANGE_DATE" && (
          <>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Start Date"
                name="start_date"
                type="date"
                value={dateRange?.start_date}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: dateRange?.end_date,
                }}
                onChange={(e) => handleDateRange(e)}
              />
            </Grid>
            <Grid item lg={0.2} md={3} sm={12} xs={12}>
              <span className="w-100 fs-4 d-flex justify-content-center align-items-center">
                -
              </span>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <TextField
                fullWidth
                size="small"
                label="End Date"
                name="end_date"
                type="date"
                value={dateRange?.end_date}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min:
                    dateRange?.start_date ||
                    JSON.stringify(new Date()).split("T")[0].slice(1),
                }}
                onChange={(e) => handleDateRange(e)}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={3} className="mb-3">
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TabContext className="subtitle-text" value={selMatchType}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "white",
              }}
            >
              <TabList
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    border: "1px solid black",
                  },
                }}
                sx={{
                  "& .MuiTab-root": {
                    fontFamily: "Inter-SemiBold",
                  },
                  ".Mui-selected": {
                    color: `#171717 !important`,
                  },
                }}
              >
                {tabs.map((item, index) => {
                  return <Tab key={index} label={item} value={item} />;
                })}
              </TabList>
            </Box>
          </TabContext>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {(matchData &&
          Array.isArray(matchData) &&
          matchData.length > 0 &&
          matchData.map((item, index) => {
            let curmatch = getMatchPosition(item);
            // console.log(curmatch,'curmatchcurmatch')
            return (
              <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
                <div
                  className="rounded p-3 pb-2 bg-white cursor-pointer"
                  style={{ boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.25)" }}
                  onClick={() => {
                    getCurrentMatch(item);
                  }}
                >
                  <div className="d-flex justify-content-between align-center">
                    <div style={{ fontSize: 13 }}>
                      <h6>{`${item.team1.team_name} vs ${item.team2.team_name}`}</h6>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          fontSize: 13,
                          padding: "5px 11px",
                          borderRadius: "5px",
                          textTransform: "capitalize",
                          fontFamily: "Oswald, sans-serif",
                          lineHeight: "1.4",
                          boxShadow: "none",
                          backgroundColor:
                            item?.status === "LIVE"
                              ? "#de3163"
                              : item?.status === "UPCOMING"
                              ? "#FFAA05"
                              : "#262626",
                        }}
                      >
                        {status[item?.status]}
                      </Button>
                    </div>
                  </div>
                  <div style={{ fontSize: 14 }} className="mt-1">
                    <div className="text-secondary d-flex justify-content-between">
                      <span>
                        {(item?.match_settings?.date &&
                          moment(item.match_settings.date.slice(0, 16)).format(
                            "DD-MMM-YYYY, h:mm A"
                          )) ||
                          moment(item.date.slice(0, 16)).format(
                            "DD-MMM-YYYY, h:mm A"
                          )}
                      </span>
                      <span>{item?.match_settings?.no_of_overs} Over</span>
                    </div>
                    <div className="text-secondary">
                      <span>
                        <SlLocationPin className="fs-6" />
                      </span>
                      {item?.match_settings?.city}
                    </div>
                  </div>

                  <hr style={{ marginTop: 8, marginBottom: 8 }} />
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ fontSize: 14 }}
                  >
                    {(curmatch && curmatch.inning1.batting_team) ||
                      item?.teamA_name}
                    <div>
                      <span
                        className="fs-6"
                        style={{ fontWeight: "600", color: "#333" }}
                      >
                        {(curmatch &&
                          curmatch?.inning1?.runs_scored &&
                          curmatch.inning1.runs_scored +
                            "/" +
                            curmatch?.inning1?.wickets_lost) ||
                          "Not Started"}
                      </span>
                      <span className="text-secondary">
                        {(curmatch &&
                          curmatch?.inning1?.overs_played &&
                          `(${getApproxOvers(
                            curmatch.inning1.overs_played
                          )} ov)`) ||
                          ""}
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-2"
                    style={{ fontSize: 14 }}
                  >
                    {(curmatch && curmatch.inning2.batting_team) ||
                      item?.teamB_name}
                    <div>
                      <span
                        className="fs-6"
                        style={{ fontWeight: "600", color: "#333" }}
                      >
                        {(curmatch &&
                          curmatch?.inning2?.runs_scored &&
                          curmatch.inning2.runs_scored +
                            "/" +
                            curmatch.inning2.wickets_lost) ||
                          "Not Started"}
                      </span>
                      <span className="text-secondary">
                        {(curmatch &&
                          curmatch?.inning2?.overs_played &&
                          `(${getApproxOvers(
                            curmatch.inning2.overs_played
                          )} ov)`) ||
                          ""}
                      </span>
                    </div>
                  </div>
                  <hr style={{ marginTop: 8, marginBottom: 8 }} />
                  <div>
                    <div className="text-center">
                      <h6 style={{ fontSize: 12 }}>
                        {item?.extras
                          ? item.extras.result === "NORESULT"
                            ? "No Result"
                            : `${
                                item.extras.team_id === item.teamA_name
                                  ? item.teamA_name
                                  : item.teamB_name
                              } got BYE`
                          : curmatch
                          ? curmatch?.outcome
                          : "Not Started"}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })) || (
          <Grid item lg={12} md={6} sm={12} xs={12}>
            {/* <SimpleCard> */}
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              {loading ? <Spinner /> : "No Matches Found"}
            </div>
            {/* </SimpleCard> */}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default MatchList;
