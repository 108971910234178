import { _get, _postAuth, _putAuth } from "api/Apimethod";
import constant from "constant";

export const getAminityListVendor = async (params) => {
  let url = constant.Vendor.VendorAmenities;
  const response = await _get(url);
  return response;
};

export const getRulesListVendor = async () => {
  let url = constant.Vendor.VendorRules;
  const response = await _get(url);
  return response;
};

export const addTurf = async (data) => {
  let url = constant.Vendor.Spots;
  const response = await _postAuth(url, data);
  return response;
};
export const addAminity = async (data) => {
  let url = constant.Vendor.AddAminityVendor;
  const response = await _postAuth(url, data);
  return response;
};
export const addRules = async (data) => {
  let url = constant.Vendor.AddRulesVendor;
  const response = await _postAuth(url, data);
  return response;
};

export const addPeronalDetail = async (data, id) => {
  let url = constant.Vendor.Spots_Personal_Detail + "/" + id;
  const response = await _putAuth(url, data);
  return response;
};

export const addGallery = async (data, id) => {
  let url = constant.Vendor.Spots_Gallery + "/" + id;
  const response = await _postAuth(url, data);
  return response;
};
export const addBusinessDetail = async (data) => {
  let url = constant.Vendor.Spots_Business_Detail;
  const response = await _putAuth(url, data);
  return response;
};

export const getSingleSpot = async (id) => {
  let url = constant.Vendor.SingleSpot + "/" + id;
  const response = await _get(url);
  return response;
};

export const activeAminity = async (data) => {
  let url = constant.Admin.Aminity + "/" + data.id;
  const response = await _putAuth(url, data);
  return response;
};

export const getBanksName = async () => {
  let url = constant.Vendor.BanksName;
  const response = await _get(url);
  return response;
};
