import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const FaqListing = Loadable(lazy(() => import("./FaqListing")));
const AddFaq = Loadable(lazy(() => import("./AddFaq")));
const EditFaq = Loadable(lazy(() => import("./EditFaq")));

const FaqRoutes = [
  {
    path: "/faq-list",
    element: <FaqListing />,
    auth: authRoles.guest,
  },

  {
    path: "/faq-list/add-faq",
    element: <AddFaq />,
    auth: authRoles.guest,
  },
  {
    path: "/faq-list/edit-faq/:id",
    element: <EditFaq />,
    auth: authRoles.guest,
  },
];

export default FaqRoutes;
