import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const SubscriptionPlanList = Loadable(
  lazy(() => import("./SubscriptionPlanList"))
);
const AddSubscriptionPlan = Loadable(
  lazy(() => import("./AddSubscriptionPlan"))
);
const SubscriptionPlanDetail = Loadable(
  lazy(() => import("./SubscriptionPlanDetail"))
);

const SubscriptionPlanRoutes = [
  {
    path: "/subscription-plan-list",
    element: <SubscriptionPlanList />,
    auth: authRoles.guest,
  },
  {
    path: "/subscription-plan-list/add-subscription-plan",
    element: <AddSubscriptionPlan />,
    auth: authRoles.guest,
  },
  //   {
  //     path: "/advertisement-list/edit-advertisement/:id",
  //     element: <EditPolls />,
  //     auth: authRoles.guest,
  //   },
  {
    path: "/subscription-plan-list/subscription-plan-detail/:id",
    element: <SubscriptionPlanDetail />,
    auth: authRoles.guest,
  },
];

export default SubscriptionPlanRoutes;
