import { _post, convertObjToQueryStr } from "api/Apimethod";
import constant from "constant";

export const authLogin = async (data) => {
  let url = constant.Vendor.Login;
  const response = await _post(url, data);
  return response;
};

export const resendOtp = async (params) => {
  // let url = constant.Vendor.ResendOTP+"country_code="+data?.country_code+"&"+"phone_number="+data?.phone_number
  let url = constant.Vendor.ResendOTP;
  let str = convertObjToQueryStr(params);
  url = url + str;
  const response = await _post(url);
  return response;
};
