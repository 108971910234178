import ChildBanner from "app/components/web/ChildBanner";
import { Link } from "react-router-dom";

const PrivacyAndpolicy = () => {
  return (
    <>
      <div className="about-banner">
        <div className="container">
          <div className="section_title">
            <span className="title_badge mb-1">Privacy Policy</span>
            <h2>Your Privacy, Our Priority</h2>
            <p>
              At Cricksage, we value your trust and are committed to protecting
              your personal information. <br /> Your data is safe, secure, and
              handled with the utmost care—because your privacy matters to{" "}
              <br /> us.
            </p>
          </div>
        </div>
      </div>
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="policy-content">
                <div className="policy-box">
                  <h6>1. Introduction</h6>
                  <ul>
                    <li>
                      <p>
                        CRICKSAGE ("we", "us", "our") is committed to protecting
                        the privacy of our users ("you", "your"). This Privacy
                        Policy explains how we collect, use, and disclose your
                        personal information when you use our website/Mobile App
                        and services
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>2. Collection of Personal Information</h6>
                  <ul>
                    <li>
                      <p>
                        We collect personal information from you when you
                        register an account, book a cricket ground, make a
                        payment, or communicate with us through the
                        website/Mobile App or other channels. The personal
                        information we collect may include your name, email
                        address, phone number, billing address, and payment
                        information.
                      </p>
                    </li>
                    <li>
                      <p>
                        We may also collect non-personal information, such as
                        your IP address, browser type, and operating system, for
                        statistical purposes and to improve our website/Mobile
                        App and services.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>3. Use of Personal Information</h6>

                  <ul>
                    <li>
                      <p>
                        We use your personal information to provide and improve
                        our website/Mobile App and services, including
                        processing your bookings, communicating with you, and
                        resolving any issues you may encounter. We may also use
                        your personal information to send you promotional offers
                        and newsletters if you have opted in to receive them.
                      </p>
                    </li>
                    <li>
                      <p>
                        We do not sell or share your personal information with
                        third parties for marketing purposes. However, we may
                        share your personal information with our partners and
                        service providers, such as payment processors and IT
                        support, to facilitate our operations and provide you
                        with better service.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>4. Security of Personal Information</h6>

                  <ul>
                    <li>
                      <p>
                        We take reasonable measures to protect your personal
                        information from unauthorized access, use, or
                        disclosure. We use industry-standard security protocols,
                        such as SSL encryption, to secure your personal
                        information during transmission and storage.
                      </p>
                    </li>
                    <li>
                      <p>
                        However, no method of transmission over the Internet or
                        electronic storage is 100% secure. Therefore, we cannot
                        guarantee the absolute security of your personal
                        information, and we are not liable for any unauthorized
                        access or use of your personal information.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>5. Cookies and Tracking Technologies</h6>

                  <ul>
                    <li>
                      <p>
                        We use cookies and other tracking technologies to
                        improve your user experience and personalize your
                        interactions with our website/Mobile App. A cookie is a
                        small text file that is stored on your device when you
                        visit our website/Mobile App. It enables us to remember
                        your preferences and provide you with customised content
                        and features.
                      </p>
                    </li>
                    <li>
                      <p>
                        You may choose to disable cookies on your device, but
                        this may affect the functionality and performance of our
                        website/Mobile App and services.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>5. Links to Third-Party website/Mobile App</h6>

                  <ul>
                    <li>
                      <p>
                        Our website may contain links to third-party
                        website/Mobile App that are not owned or controlled by
                        us. We are not responsible for the privacy practices or
                        content of these website/Mobile App. We recommend that
                        you read the privacy policies of these website/Mobile
                        App before providing any personal information to them.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>6. Changes to Privacy Policy</h6>

                  <ul>
                    <li>
                      <p>
                        We reserve the right to modify or update this Privacy
                        Policy at any time without prior notice. The revised
                        Privacy Policy will be effective from the date of
                        publication on our website/Mobile App. We encourage you
                        to review this Privacy Policy periodically to stay
                        informed about our data practices.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>7. Contact Us</h6>
                  <ul>
                    <li>
                      <p>
                        If you have any questions or concerns about this Privacy
                        Policy or our data practices, please contact us at{" "}
                        <span>
                          <Link to="info@cricksage.com">
                            info@cricksage.com
                          </Link>
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer/> */}
    </>
  );
};

export default PrivacyAndpolicy;
