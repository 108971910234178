import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";
import TeamDetail from "./TeamDetail";
import MatchList from "../Matches/MatchList";

const Teams = Loadable(lazy(() => import("./Teams")));

const teamRoutes = [
  { path: "/teams", element: <Teams />, auth: authRoles.admin },
  {
    path: "/teams/details/:id",
    element: <TeamDetail />,
    auth: authRoles.admin,
  },
  { path: "/match-list", element: <MatchList />, auth: authRoles.admin },
];

export default teamRoutes;
