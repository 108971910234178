import { authRoles } from "../../../auth/authRoles";
import { VendorNotification } from "./VendorNotification";

const NotificationRoute = [
  {
    path: "/vendor-notification",
    element: <VendorNotification />,
    auth: authRoles.guest,
  },
];

export default NotificationRoute;
