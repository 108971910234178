import NewLogo3 from "../../Assets/images/NewLogo3.png";
const MatxLogo = ({ className }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "64px", backgroundColor: "#ffffff" }}
    >
      <img src={NewLogo3} alt="" style={{ width: "90%" }} />
    </div>
  );
};

export default MatxLogo;
