import React from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import { MatxLoading } from "app/components";
import { DefaultImg } from "Assets";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlayerStats } from "services/admin/Dashboard";
import { getUser } from "services/admin/User";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
export default function Bat({ state, loader }) {
  const formateNumber = (num) => {
    if (Number(num) % 1 !== 0) {
      return parseFloat(Number(num).toFixed(1));
    } else {
      return parseFloat(num);
    }
  };

  return (
    <div>
      {(loader && (
        <div className="text-center mt-2">
          <MatxLoading />
        </div>
      )) ||
        (state?.leather?.batting || state?.tennis?.batting ? (
          <div className="mt-4 table-responsive">
            <Table
              className="border"
              sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead style={{ backgroundColor: "#222B42" }}>
                {/* ------------------ Batting Header ------------- */}

                <TableRow>
                  <TableCell
                    width={"12%"}
                    className="text-white"
                    align="center"
                  >
                    Batting
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Matches
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Innings
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Not Out
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Runs
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Highest
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Average
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    SR
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    30s
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    50s
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    100s
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    4s
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    6s
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Duck
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Won
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Loss
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* ------------------ Batting Overall ------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Overall
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.match ||
                      0 + state?.leather?.batting?.match ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.innings ||
                      0 + state?.leather?.batting?.innings ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.not_out ||
                      0 + state?.leather?.batting?.not_out ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.runs_scored ||
                      0 + state?.leather?.batting?.runs_scored ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.highest >
                    state?.leather?.batting?.highest
                      ? state?.tennis?.batting?.highest
                      : state?.leather?.batting?.highest}
                  </TableCell>
                  <TableCell align="center">
                    {/* {Number(bataverage) > 0
                            ? Number(bataverage).toFixed(2)
                            : 0} */}
                    {formateNumber(
                      state?.tennis?.batting?.avg ||
                        0 + state?.leather?.batting?.avg ||
                        0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {formateNumber(
                      state?.tennis?.batting?.sr ||
                        0 + state?.leather?.batting?.sr ||
                        0
                    )}
                    {/* {Number(batsr) > 0 ? Number(batsr).toFixed(2) : 0} */}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.thirty ||
                      0 + state?.leather?.batting?.thirty ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.fifty ||
                      0 + state?.leather?.batting?.fifty ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.hundred ||
                      0 + state?.leather?.batting?.hundred ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.fours ||
                      0 + state?.leather?.batting?.fours ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.sixes ||
                      0 + state?.leather?.batting?.sixes ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.duck ||
                      0 + state?.leather?.batting?.duck ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.won ||
                      0 + state?.leather?.batting?.won ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.loss ||
                      0 + state?.leather?.batting?.loss ||
                      0}
                  </TableCell>
                </TableRow>

                {/* ------------------ Batting Leather Ball	------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Leather Ball
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.match || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.innings || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.not_out || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.runs_scored || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.highest || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {Number(state?.leather?.batting?.avg) > 0
                      ? Number(state?.leather?.batting?.avg).toFixed(2)
                      : 0}
                  </TableCell>
                  <TableCell align="center">
                    {Number(state?.leather?.batting?.sr) > 0
                      ? Number(state?.leather?.batting?.sr).toFixed(2)
                      : 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.thirty || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.fifty || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.hundred || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.fours || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.sixes || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.duck || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.won || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.batting?.loss || "0"}
                  </TableCell>
                </TableRow>

                {/* ------------------ Batting Tennis Ball ------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Tennis Ball
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.match || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.innings || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.not_out || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.runs_scored || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.highest || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {Number(state?.tennis?.batting?.avg) > 0
                      ? Number(state?.tennis?.batting?.avg).toFixed(2)
                      : 0}
                  </TableCell>
                  <TableCell align="center">
                    {Number(state?.tennis?.batting?.sr) > 0
                      ? Number(state?.tennis?.batting?.sr).toFixed(2)
                      : 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.thirty || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.fifty || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.hundred || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.fours || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.sixes || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.duck || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.won || "0"}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.batting?.loss || "0"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          ""
        ))}
    </div>
  );
}
