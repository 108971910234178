import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const PollsList = Loadable(lazy(() => import("./PollsList")));
const AddPolls = Loadable(lazy(() => import("./AddPolls")));
const EditPolls = Loadable(lazy(() => import("./EditPolls")));
const PollsDetail = Loadable(lazy(() => import("./PollsDetail")));

const PollsRoutes = [
  {
    path: "/polls-list",
    element: <PollsList />,
    auth: authRoles.guest,
  },
  {
    path: "/polls-list/add-polls",
    element: <AddPolls />,
    auth: authRoles.guest,
  },
  {
    path: "/polls-list/edit-polls/:id",
    element: <EditPolls />,
    auth: authRoles.guest,
  },
  {
    path: "/polls-list/polls-detail/:id",
    element: <PollsDetail />,
    auth: authRoles.guest,
  },
];

export default PollsRoutes;
