import Loadable from "app/components/Loadable";
import { lazy } from "react";

const HomePage = Loadable(lazy(() => import("./HomePage")));
const FaqPage = Loadable(lazy(() => import("../chooseUs/Faq")));

const homeRoutes = [
  { path: "/", element: <HomePage /> },
  { path: "/faq", element: <FaqPage /> },
];
export default homeRoutes;
