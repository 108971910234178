import axios from "axios.js";
import Cookies from "js-cookie";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export const _post = async (url, data = {}) => {
  return await axios
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

export const _postAuth = async (url, data = {}) => {
  return await axios
    .post(url, data, { headers: { Authorization: `${_getToken()}` } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

export const _get = async (url, navigate) => {
  return await axios
    .get(url, {
      headers: {
        Authorization: `${_getToken()}`,
        "ngrok-skip-browser-warning": "69420",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err, "err");
      if (err?.code === 401 && navigate) {
        Cookies.remove("authToken", { path: "/" });
        Cookies.remove("role", { path: "/" });
        navigate("/");
        return false;
      }
    });
};

export const _patch = async (url, data = {}) => {
  return await axios
    .patch(url, data, { headers: { Authorization: _getToken() } })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const _put = async (url, data = {}) => {
  return await axios
    .put(url, data)
    .then((res) => {
      return res.message.data;
    })
    .catch((err) => {
      return {
        status: false,
        message: err,
      };
    });
};

// export const _putAuth = async (url, data = {}) => {

//   return await axios.put(url, data, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       "x-rapidapi-host": "file-upload8.p.rapidapi.com",
//       "x-rapidapi-key": "your-rapidapi-key-here", "Authorization": `${_getToken()}`
//     }
//   }).then((res) => {
//     return res.data
//   }).catch((err) => {

//     return {
//       status: false,
//       message: err
//     }
//   })

// }

export const _putAuth = async (url, data = {}) => {
  return await axios
    .put(url, data, { headers: { Authorization: `${_getToken()}` } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return {
        status: false,
        message: err,
      };
    });
};

export const _getToken = () => {
  const token = Cookies.get("authToken");
  return token;
};

export const _delete = async (url, data) => {
  const response = await axios.delete(url, {
    headers: {
      Authorization: `${_getToken()}`,
    },
    data: {
      _id: data?._id,
    },
  });
  return response;
};
export const _deleteApi = async (url, data) => {
  const response = await axios.delete(url, {
    headers: { Authorization: `${_getToken()}` },
    data: data,
  });
  return response;
};

export const _deleteAuth = async (url, data, _getToken) =>
  await axios.delete(url, {
    headers: {
      Authorization: `${_getToken()}`,
    },
    data,
  });

export const convertObjToQueryStr = (params) => {
  const objStr =
    "?" +
    Object.keys(params)
      .map((key) => {
        return `${key}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
  return objStr;
};
