import { _get, convertObjToQueryStr } from "api/Apimethod";
import constant from "constant";

export const getAvailableAndBookedSlot = async (params) => {
  let url = constant.Vendor.Occupancy;
  let str = convertObjToQueryStr(params);
  url = url + str;
  const response = await _get(url);
  return response;
};
// export const getSingleSlot = async (id) => {
//   let url = constant.Vendor.Occupancy+"/"+id
//   const response = await _get(url);
//   return response;
// };
