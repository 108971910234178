import React from "react";
import AddBusiness from "./AddBusiness";
import AddKYC from "./AddKYC";
import AccountDetail from "./AccountDetail";

const VerifyVendorRoute = [
  {
    path: "/vendor-verify",
    element: <AddBusiness />,
  },
  {
    path: "/vendor-addKYC",
    element: <AddKYC />,
  },
  {
    path: "/vendor-account-detail",
    element: <AccountDetail />,
  },
];

export default VerifyVendorRoute;
