import { createSlice } from "@reduxjs/toolkit";

export const VendoreLogin = createSlice({
  name: "Login",

  initialState: {
    value: 0,
  },
  reducers: {
    LoginID: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { LoginID } = VendoreLogin.actions;

export default VendoreLogin.reducer;
