import { setLoginOtp } from "app/redux/reducers/Otp";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authSignup } from "services/web/Signup";
import * as Yup from "yup";
// import "../../../../Assets/css/style.css";
import "../../../../Assets/css/web.css";

import { encryptData } from "app/helperFuntion/encryptDecrypt";
import { Login1, NewLogo3 } from "Assets";
import { FaArrowLeft } from "react-icons/fa";
import { Checkbox, InputAdornment, TextField } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { Spinner } from "react-bootstrap";

const initialValues = {
  phone_number: "",
  country_code: 91,
  terms_key: false,
};
const LoginPage = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    terms_key: Yup.bool().oneOf(
      [true],
      "Please accept term & conditions and privacy policy"
    ),
    phone_number: Yup.string()
      .min(10, "The Mobile Number field must be at least 10 characters")
      .max(10, " The Mobile Number field must be at least 10 characters")
      .matches("^[0-9]", "Please enter your valid mobile number")
      .required("Mobile number is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      await authSignup(values)
        .then((res) => {
          setLoader(false);
          if (res?.status) {
            dispatch(setLoginOtp(res?.data));
            navigate(`/login-otp/${encryptData(values.phone_number)}`);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.message);
          setLoader(false);
        });
    },
  });
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleError = () => {
    toast.warning("Only for india");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === "" || (value && value.length < 11)) {
      setFieldValue(name, value);
    }
  };

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <>
      {/* <section className="login-wap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-7 py-4">
              <div className="login-image ">
                <SliderIMG />
              </div>
            </div>
            <div className="col-sm-5 d-flex align-items-center">
              <div className="login-box my-4">
                <div className="login-content">
                  <h3 className="logintext text-center">Login as a vendor</h3>
                  <p
                    className="mt-2 vendor-p"
                    style={{ color: "#33333399", textAlign: "center" }}
                  >
                    Please sign in to continue
                  </p>
                  <form
                    className="from-inline"
                    id="loginfrom"
                    onSubmit={handleSubmit}
                  >
                    <div className="row mt-5">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <TextField
                            fullWidth
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            placeholder="Please enter mobile number"
                            id="outlined-start-adornment"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  onClick={() => handleError()}
                                >
                                  +91
                                  <BsChevronDown className="fs-6 ms-1" />
                                </InputAdornment>
                              ),

                              sx: {
                                "&::placeholder": {
                                  color: "rgb(255 255 255 / 50%)",
                                  opacity: 1,
                                  fontSize: "14px",
                                  fontWeight: "300",
                                },
                              },
                            }}
                            name="phone_number"
                            value={values.phone_number}
                            onChange={(e) => handleChange(e)}
                            error={errors.phone_number && touched.phone_number}
                            helperText={
                              touched.phone_number && errors.phone_number
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12 d-flex">
                        <div className="form-check ps-0">
                          <Checkbox
                            className=""
                            id="flexCheckDefault"
                            size="small"
                            style={{ color: "#ff5722" }}
                            name="terms_key"
                            value={values.terms_key}
                            {...getFieldProps("terms_key")}
                            helperText={touched.terms_key && errors.terms_key}
                            error={Boolean(
                              errors.terms_key && touched.terms_key
                            )}
                          />
                        </div>
                        <div>
                          <p
                            htmlFor="flexCheckDefault"
                            style={{ marginTop: "22px" }}
                            className="vendor-p"
                          >
                            By creating passcode you agree with our
                            <Link
                              to="/term-condition"
                              style={{ color: "#FF5722" }}
                            >
                              {" "}
                              Terms & Conditions{" "}
                            </Link>{" "}
                            and{" "}
                            <Link
                              to="/privacy-policy"
                              style={{ color: "#FF5722" }}
                            >
                              {" "}
                              Privacy Policy
                            </Link>
                          </p>
                          {touched.terms_key && errors.terms_key ? (
                            <p className="error-message">{errors.terms_key}</p>
                          ) : (
                            ""
                          )}
                          <div></div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group mt-2 mb-3">
                          <button type="submit" className="Login-button">
                            {(loader && (
                              <Spinner
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            )) ||
                              " SIGN IN"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <ul className="social-icon text-center">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61564130858773"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/cricksage_app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/cricksage"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="login-top-content">
                <p onClick={() => handleNavigate()} className="cursor-pointer">
                  {/* <a href="/"> */}
                  <FaArrowLeft style={{ color: "#FF5722" }} /> Back to home
                  {/* </a>{" "} */}
                </p>
                <img src={NewLogo3} alt="login" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <div className="login-container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="login-container-text">
                      <h1>
                        login & manage <br /> customers!
                      </h1>
                      <p>
                        Access your account in seconds and take control of your
                        customer interactions with ease. Manage, track, and grow
                        your bussiness—all from one powerful dashboard!
                      </p>
                      <img src={Login1} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="login-container-form">
                      <span className="welcome">Welcome Back</span>
                      <h1>Login as a vendor</h1>
                      <p>Please sign in to continue</p>
                      <form onSubmit={handleSubmit} className="login-form">
                        <div className="text-start mb-3">
                          <label className="mb-1">Mobile Number</label>
                          <TextField
                            fullWidth
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            placeholder="Please enter mobile number"
                            id="outlined-start-adornment"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  onClick={() => handleError()}
                                >
                                  +91
                                  <BsChevronDown className="fs-6 ms-1" />
                                </InputAdornment>
                              ),

                              sx: {
                                borderRadius: "4px",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#D6D6D6",
                                  },
                                },

                                "&::placeholder": {
                                  color: "rgb(255 255 255 / 50%)",
                                  opacity: 1,
                                  fontSize: "14px",
                                  fontWeight: "300",
                                },
                              },
                            }}
                            name="phone_number"
                            value={values.phone_number}
                            onChange={(e) => handleChange(e)}
                            error={errors.phone_number && touched.phone_number}
                            helperText={
                              touched.phone_number && errors.phone_number
                            }
                          />
                        </div>
                        <div className="d-flex">
                          <div>
                            <Checkbox
                              size="small"
                              style={{ color: "#ff5722", paddingLeft: "0" }}
                              name="terms_key"
                              value={values.terms_key}
                              {...getFieldProps("terms_key")}
                              helperText={touched.terms_key && errors.terms_key}
                              error={Boolean(
                                errors.terms_key && touched.terms_key
                              )}
                            />
                          </div>
                          <div className="term-condition">
                            <p>
                              By creating passcode you agree with our
                              <Link
                                to="/term-condition"
                                style={{ color: "#FF5722" }}
                              >
                                {" "}
                                Terms & Conditions{" "}
                              </Link>{" "}
                              and{" "}
                              <Link
                                to="/privacy-policy"
                                style={{ color: "#FF5722" }}
                              >
                                {" "}
                                Privacy Policy
                              </Link>
                            </p>
                            {touched.terms_key && errors.terms_key ? (
                              <p className="error-message">
                                {errors.terms_key}
                              </p>
                            ) : (
                              ""
                            )}
                            <div></div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-2 mb-3">
                          <button type="submit" className="Login-button">
                            {(loader && (
                              <Spinner
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            )) ||
                              " SIGN IN"}
                          </button>
                        </div>
                      </form>

                      <ul className="social-icon text-center">
                        <li>
                          <a
                            href="https://www.facebook.com/profile.php?id=61564130858773"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/cricksage_app/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/cricksage"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
