import { BsCalendarPlus, BsFileText, BsPostcard } from "react-icons/bs";
import { FaRegAddressCard, FaRegUser, FaRegUserCircle } from "react-icons/fa";
import { FaMoneyCheckDollar, FaQuestion } from "react-icons/fa6";
import { GiDiamondTrophy } from "react-icons/gi";
import {
  MdOutlineAnalytics,
  MdOutlineDashboard,
  MdOutlinePoll,
  MdOutlinePostAdd,
  MdOutlineSocialDistance,
  MdPayment,
} from "react-icons/md";
import { PiFlagBanner } from "react-icons/pi";
import { RiFileEditLine, RiFileShield2Line, RiTeamFill } from "react-icons/ri";
import { SlBadge } from "react-icons/sl";
import { TbCricket, TbReceiptTax } from "react-icons/tb";
import { CgWebsite } from "react-icons/cg";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard/default",
    icon: <MdOutlineDashboard />,
  },
  {
    name: "Social Analytics",
    path: "/analytics",
    icon: <MdOutlineAnalytics />,
  },
  { name: "User", path: "/user-list", icon: <FaRegUser /> },
  {
    name: "Vendor",
    icon: <FaRegUserCircle />,
    children: [
      { name: "Vendor List", path: "/vendor-list", icon: <FaRegUserCircle /> },
      { name: "Vendor Payouts", path: "/vendor-payout", icon: <MdPayment /> },
      {
        name: "Settlement",
        path: "/settlements",
        icon: <FaMoneyCheckDollar />,
      },
    ],
  },
  {
    name: "Spots",
    icon: <FaRegUserCircle />,
    children: [
      { name: "Turf / Ground", path: "/turflist", icon: <BsPostcard /> },
      {
        name: "Amenities",
        path: "/amenity/amenity-list",
        icon: <RiFileShield2Line />,
      },
      { name: "Rules", path: "/rules/rules-list", icon: <BsFileText /> },
    ],
  },

  { name: "Bookings", path: "/booking-list-admin", icon: <BsCalendarPlus /> },

  {
    name: "Tournaments",
    icon: <GiDiamondTrophy />,
    children: [
      {
        name: "My Tournaments",
        path: "/tournament/my-tournaments",
        icon: <GiDiamondTrophy />,
      },
      {
        name: "User Tournaments",
        path: "/tournament/user-tournaments",
        icon: <GiDiamondTrophy />,
      },
      {
        name: "Vendor Tournaments",
        path: "/tournament/vendor-tournaments",
        icon: <GiDiamondTrophy />,
      },
      { name: "Scorer", path: "/scorer/list", icon: <FaRegAddressCard /> },
      { name: "Manager", path: "/manager/list", icon: <FaRegAddressCard /> },
    ],
  },

  { name: "Teams", path: "/teams", icon: <RiTeamFill /> },
  { name: "Matches", path: "/match-list", icon: <TbCricket /> },

  {
    name: "Social",
    icon: <MdOutlineSocialDistance />,
    children: [
      {
        name: "Post",
        path: "/post-list",
        icon: <MdOutlinePostAdd />,
      },
      {
        name: "Polls",
        path: "/polls-list",
        icon: <MdOutlinePoll />,
      },
    ],
  },

  {
    name: "Website",
    icon: <CgWebsite />,
    children: [
      {
        name: "Blogs",
        path: "/blog-list",
        icon: <i className="fa-solid fa-blog"></i>,
      },
      {
        name: "FAQ",
        path: "/faq-list",
        icon: <FaQuestion />,
      },
      {
        name: "App screens",
        path: "/app-screens-list",
        icon: <MdOutlinePoll />,
      },
    ],
  },

  {
    name: "Advertisement",
    path: "/advertisement-list",
    icon: <MdOutlinePoll />,
  },

  { name: "Inquiry", path: "/inquiry", icon: <FaQuestion /> },

  { name: "Tax and Charges", path: "/tax-and-charges", icon: <TbReceiptTax /> },

  {
    name: "Banner",
    path: "/banner-list",
    icon: <PiFlagBanner />,
  },
  {
    name: "Badges",
    path: "/badges-list",
    icon: <SlBadge />,
  },

  { name: "Promo Code", path: "/promocode/promocode-list", icon: "redeem" },

  { name: "Reports", path: "/report-list", icon: <RiFileEditLine /> },

  {
    name: "Subscription Plan",
    path: "/subscription-plan-list",
    icon: <MdOutlinePoll />,
  },
  // {
  //   name: "Subscriptions",
  //   path: "/subscription-list",
  //   icon: <FaMoneyCheckAlt />,
  // },
  // { name: "Ads", path: "/add/ads", icon: <RiAdvertisementLine /> },
  // { name: "Banner", path: "/banner-list", icon: <BsPostcard /> },
];
