import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const UserList = Loadable(lazy(() => import("./UserList")));
const UserProfile = Loadable(lazy(() => import("./UserProfile")));
const CountryList = Loadable(lazy(() => import("./CountryList")));

const userRoutes = [
  {
    path: "/user-list",
    element: <UserList />,
    auth: authRoles.guest,
  },
  {
    path: "/country-list",
    element: <CountryList />,
    auth: authRoles.guest,
  },
  {
    path: "/user-list/profile/:id",
    element: <UserProfile />,
    auth: authRoles.guest,
  },
];

export default userRoutes;
