import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const AppScreensList = Loadable(
  lazy(() => import("../AppScreens/AppScreensList"))
);
const AddAppScreens = Loadable(
  lazy(() => import("../AppScreens/AddAppScreens"))
);

const AppScreensRoutes = [
  {
    path: "/app-screens-list",
    element: <AppScreensList />,
    auth: authRoles.guest,
  },

  {
    path: "/app-screens-list/app-screens-add",
    element: <AddAppScreens />,
    auth: authRoles.guest,
  },
];

export default AppScreensRoutes;
