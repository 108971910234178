import { _get, _postAuth, _putAuth, convertObjToQueryStr } from "api/Apimethod";
import constant from "constant";

export const fetchTimeAndCost = async (id) => {
  let url = constant.Vendor.SchedulAndCost.fetchTimeAndCost + "/" + id;
  const response = await _get(url);
  return response;
};
export const updateSlot = async (params, id) => {
  let url = constant.Vendor.SchedulAndCost.fetchTimeAndCost + "/" + id;
  const response = await _putAuth(url, params);
  return response;
};
export const MergeSlot = async (params, id) => {
  let url = constant.Vendor.SchedulAndCost.Merge + id;
  const response = await _putAuth(url, params);
  return response;
};

export const CreateTimeAndCost = async (data) => {
  let url = constant.Vendor.SchedulAndCost.Create;
  const response = await _postAuth(url, data);
  return response;
};

export const CreateSpecialDay = async (data) => {
  const url = constant.Vendor.SpecialDay;
  const response = _postAuth(url, data);
  return response;
};

export const fetchSpecialDay = async (id) => {
  let url = constant.Vendor.SpecialDay + "/" + id;
  const response = await _get(url);
  return response;
};
export const fetchSpecificDate = async (params) => {
  let url = constant.Vendor.SpecificDate;
  if (params) {
    let str = convertObjToQueryStr(params);
    url = url + str;
  }
  const response = await _get(url);
  return response;
};

export const getWorkingHours = async (id) => {
  // let url = constant.Vendor.TurfProfile + '?vendor_id='+id
  let url = constant.Vendor.TurfProfile + "/" + id;
  const response = await _get(url);
  return response;
};

//  Ofline booking
export const offlineBooking = async (data) => {
  let url = constant.Vendor.OfflineBooking;
  const response = await _postAuth(url, data);
  return response;
};
