import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const VendorProfilePage = Loadable(lazy(() => import("./VendorProfilePage")));
const Business = Loadable(lazy(() => import("./child/Business")));
const Personal = Loadable(lazy(() => import("./child/Personal")));
const EditPersonaldtl = Loadable(lazy(() => import("./child/EditPersonaldtl")));

const profileRoutes = [
  {
    path: "/profile",
    element: <VendorProfilePage />,
    auth: authRoles.guest,
  },

  {
    path: "/personal",
    element: <Personal />,
    auth: authRoles.guest,
  },
  {
    path: "/bussiness",
    element: <Business />,
    auth: authRoles.guest,
  },

  {
    path: "/personal-edit/:id",
    element: <EditPersonaldtl />,
    auth: authRoles.guest,
  },
];

export default profileRoutes;
