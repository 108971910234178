import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { SlLocationPin } from "react-icons/sl";
import { useParams } from "react-router-dom";
import { getTeamMatchList } from "services/admin/Tournamet";
import { scoreFunctions } from "../Tournament/StartMatch/scoring/scoreFunctions";
import { inningObj } from "../Tournament/StartMatch/scoring/scoreHelper";

const Matches = () => {
  const [loading, setLoading] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [selMatchType, setSelMatchType] = useState("ALL");

  const { id } = useParams();

  const getData = async (matchType) => {
    setLoading(true);
    setSelMatchType(matchType);
    let params = {
      id: id,
    };

    if (matchType) {
      params = { ...params, status: matchType === "ALL" ? "" : matchType };
    }
    await getTeamMatchList(params)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setMatchData(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(selMatchType);
  }, []);

  const getApproxOvers = (overs) => {
    return (
      (String(overs).split(".")[1] === "6" &&
        Number(String(overs).split(".")[0]) + 1) ||
      overs
    );
  };

  const getMatchPosition = (curmatch) => {
    let obj = false;
    if (curmatch) {
      let inning1;
      let inning2;
      if (curmatch?.super_over && curmatch.super_over.length > 0) {
        inning1 =
          curmatch.super_over[curmatch.super_over.length - 1].innings[0];
        inning2 =
          curmatch.super_over[curmatch.super_over.length - 1].innings[1];
      } else if (curmatch.innings) {
        inning1 =
          (curmatch.innings[0].first_bat && curmatch.innings[0]) ||
          curmatch.innings[1];
        inning2 =
          (curmatch.innings[0].first_bat && curmatch.innings[1]) ||
          curmatch.innings[0];
      } else {
        inning1 = inningObj;
        inning2 = inningObj;
      }
      obj = {
        inning1: { ...inning1 },
        inning2: { ...inning2 },
        outcome: curmatch.innings ? scoreFunctions.getOutCome(curmatch) : null,
      };
    }
    return obj;
  };

  return (
    <div>
      <Grid container spacing={3} className="mb-3">
        <Grid item lg={10} md={6} sm={12} xs={12}>
          <h2 className="h2-chart pt-2">Matches</h2>
        </Grid>

        <Grid item lg={2} md={3} sm={12} xs={12}>
          <FormControl fullWidth size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              name="filter"
              value={selMatchType}
              onChange={(e) => getData(e.target.value)}
            >
              <MenuItem value="ALL">ALL</MenuItem>
              <MenuItem value="LIVE">LIVE</MenuItem>
              <MenuItem value="UPCOMING">UPCOMING</MenuItem>
              <MenuItem value="COMPLETED">COMPLETED</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {(matchData &&
          Array.isArray(matchData) &&
          matchData.length > 0 &&
          matchData.map((item, index) => {
            let curmatch = getMatchPosition(item);
            return (
              <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
                <div
                  className="rounded p-3 pb-2 bg-white cursor-pointer"
                  style={{ boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.25)" }}
                >
                  <div className="d-flex justify-content-between align-center">
                    <div style={{ fontSize: 13 }}>
                      <h6>{`${item.team1.team_name} vs ${item.team2.team_name}`}</h6>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          fontSize: 13,
                          padding: "5px 11px",
                          borderRadius: "5px",
                          textTransform: "capitalize",
                          fontFamily: "Oswald, sans-serif",
                          lineHeight: "1.4",
                          boxShadow: "none",
                          backgroundColor:
                            item?.status === "LIVE"
                              ? "#de3163"
                              : item?.status === "UPCOMING"
                              ? "#FFAA05"
                              : "#262626",
                        }}
                      >
                        {item?.status}
                      </Button>
                    </div>
                  </div>
                  <div style={{ fontSize: 14 }} className="mt-1">
                    <div className="text-secondary d-flex justify-content-between">
                      <span>
                        {(item?.match_settings?.date &&
                          moment(item.match_settings.date.slice(0, 16)).format(
                            "DD-MMM-YYYY, h:mm A"
                          )) ||
                          moment(item.date.slice(0, 16)).format(
                            "DD-MMM-YYYY, h:mm A"
                          )}
                      </span>
                      <span>{item?.match_settings?.no_of_overs} Over</span>
                    </div>
                    <div className="text-secondary">
                      <span>
                        <SlLocationPin className="fs-6" />
                      </span>
                      {item?.match_settings?.city}
                    </div>
                  </div>

                  <hr style={{ marginTop: 8, marginBottom: 8 }} />
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ fontSize: 14 }}
                  >
                    {(curmatch && curmatch.inning1.batting_team) ||
                      item?.teamA_name}
                    <div>
                      <span
                        className="fs-6"
                        style={{ fontWeight: "600", color: "#333" }}
                      >
                        {(curmatch &&
                          curmatch?.inning1?.runs_scored &&
                          curmatch.inning1.runs_scored +
                            "/" +
                            curmatch?.inning1?.wickets_lost) ||
                          "Not Started"}
                      </span>
                      <span className="text-secondary">
                        {(curmatch &&
                          curmatch?.inning1?.overs_played &&
                          `(${getApproxOvers(
                            curmatch.inning1.overs_played
                          )} ov)`) ||
                          ""}
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center mt-2"
                    style={{ fontSize: 14 }}
                  >
                    {(curmatch && curmatch.inning2.batting_team) ||
                      item?.teamB_name}
                    <div>
                      <span
                        className=" fs-6"
                        style={{ fontWeight: "600", color: "#333" }}
                      >
                        {(curmatch &&
                          curmatch?.inning2?.runs_scored &&
                          curmatch.inning2.runs_scored +
                            "/" +
                            curmatch.inning2.wickets_lost) ||
                          "Not Started"}
                      </span>
                      <span className="text-secondary">
                        {(curmatch &&
                          curmatch?.inning2?.overs_played &&
                          `(${getApproxOvers(
                            curmatch.inning2.overs_played
                          )} ov)`) ||
                          ""}
                      </span>
                    </div>
                  </div>
                  <hr style={{ marginTop: 8, marginBottom: 8 }} />
                  <div>
                    <div className="text-center">
                      <h6 style={{ fontSize: 12 }}>
                        {item?.extras
                          ? item.extras.result === "NORESULT"
                            ? "No Result"
                            : `${
                                item.extras.team_id === item.teamA_name
                                  ? item.teamA_name
                                  : item.teamB_name
                              } got BYE`
                          : curmatch
                          ? curmatch?.outcome
                          : "Not Started"}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })) || (
          <Grid item lg={12} md={6} sm={12} xs={12}>
            {/* <SimpleCard> */}
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              {loading ? <Spinner /> : "No Matches Found"}
            </div>
            {/* </SimpleCard> */}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Matches;
