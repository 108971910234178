const constant = {
  ASSETES_URL: {
    assets: "http://localhost:3000/",
  },
  local: {
    contactUs: "/static/add_query",
  },
  Common: {
    transaction: "/admin/transaction_history_list",
  },
  googleApi: {
    place: "globle/google_place_id",
    placeId: "globle/google_place",
  },

  Admin: {
    addAnnouncement: "/admin/send_notification",
    MaintenanceDetail: "/admin/app_status",
    Maintenance: "/admin/update_app",
    UserList: "admin/user-listing",
    VendorList: "admin/vendor-listing",
    Bookings: "/spots/admin-turf",
    UserStatus: "admin/statusUpdate", // Also a approved / not approved vendor
    UserStatus1: "admin/statusUpdateUser", // Also a approved / not approved
    AddVendor: "admin/create-vendor",
    Aminity: "amenity/manage",
    Analytics: "/admin/social_feed_analysis",
    TurfList: "globle/search_by_city/",
    getRules: "rule/read",
    addRules: "rule/create",
    editRules: "rule/update",
    singleRule: "rule/read",
    deleteRule: "rule/delete",
    VendorBooking: "admin/vendor-booking",
    TurfDetail: "admin/spots-listing",
    Spots: "/spots/spot-details",
    BookingDetail: "admin/booking-details",
    Dashboard: "admin/dashboard",
    tournamentAnalytics: "admin/sports-analytics",
    SportAnalytics: "admin/sports-analytics-onetoone",
    BatAnalytics: "/admin/overall-batting",
    BallAnalytics: "/admin/overall-bowling",
    MVPAnalytics: "/admin/overall-mvp",
    BatAnalyticsOverall: "/admin/overall-batting-onetoone",
    BallAnalyticsOverall: "/admin/overall-bowling-onetoone",
    MVPAnalyticsOverall: "/admin/overall-mvp-onetoone",
    PlayerDetail: "/admin/user_profile",
    PlayerState: "/user/player_stats",
    vendorPayout: "/admin/vendor_payout_list",
    vendorBookingPayout: "/admin/vendor_payout_booking_list",
    vendorPay: "/admin/pay_vendor",
    queryList: "/static/query_list",
    Matchlist: "/user/overall_match_list",
    TeamMatchList: "/user/team_match_list",

    UserandVendor: {
      UpdateVendor: "admin/user_update",
      UpdateUser: "admin/user-profile",
      Delete: "admin/user_delete",
    },
    turfs: {
      turfList: "/spots/spot-details",
    },
    tournament: {
      fixMatch: "fixture/fix-match",
      Matchlist: "fixture/match-list",
      MnagerMatchList: "scorer/fixture-scorer",
      tournamentGround: "admin/turf-list-tournament",
      addTournament: "admin/create-tournament",
      tournamentList: "admin/tournament-list",
      newtournamentList: "admin/tournament-list-SM",
      tournamentListManager: "assigned/manager-tournament",
      tournamentListScorer: "assigned/scorer-tournament",
      MatchList: "admin/tournament-list",
      // http://192.168.1.120:8005/api/admin/tool-tip-tournament?tournament_id=6479b51fd77e180a5064bf9f,
      toolTipData: "/admin/tool-tip-tournament?",
      deteleTournament: "/admin/tournament-delete",
      editTournament: "/admin/tournament-update",
      tournamentAbout: "/admin/about/",
      addSponsors: "/sponsor/create",
      sponsorsList: "/sponsor/read/",
      deleteSponsor: "/sponsor/delete/",
      addTeam: "/admin/team-add",
      addTeamAdmin: "/tournament/register",
      teamList: "/team/team_tournament",
      manageGroup: "/teamgroup/manage-group",
      updateGroup: "/teamgroup/update_team_group",
      playingXI: "/team/manage/",
      removePlayer: "/team/player",
      addPlayer: "/team/player",
      updateTeam: "/team/manage/",
      fixtureTeamList: "/fixture/fixture-team-list/",
      AddplayingXI: "/admin/select_playing11",
      getGroups: "group/list",
      createScorer: "/scorer/register",
      createManager: "/manager/register",
      getManagerList: "/manager/list",
      getScorerList: "/scorer/list",
      changeScorerFixture: "/admin/change_scorer",
      // http://192.168.1.34:8005/api/assigned/scorer-list?manager_id=64c751743c82b8fbc367fbcc
      managerScorer: "/scorer/list",
      getSingleScorer: "/scorer/list/",
      getSingleManager: "/manager/list/",
      updateScorer: "/scorer/update",
      updateManager: "/manager/update",
      deleteScorer: "/scorer/delete/",
      deleteManager: "/manager/delete/",
      asignRole: "/assigned/scorer-tournament",
      unAssignScorer: "/scorer/remove_scorer",
      asignManager: "/assigned/manager-tournament",
      getMatches: "/fixture/tournament-match-list",
      topBatsman: "admin/batting_leaderboard",
      topBowler: "admin/bowler_leaderboard",
      topMVP: "admin/mvp_leaderboard",
      topFielder: "admin/field_leaderboard",
      topStats: "admin/overall_stats",
      matchlistscorer: "assigned_matches/scorer-matches",
      deleteFixture: "/fixture/delete",
    },
    scoring: {
      matchSetting: "match/save",
      matchData: "match/getMatchDetails/",
      updateMatch: "match/update",
      wicket: "/match/wicket",
      updateRun: "/match/updateRun",
      addNextBatsman: "/match/nextBatsman",
      addNextBowler: "/match/nextBowler",
      changeInnings: "/match/changeInnings",
      endMatch: "/match/endOfMatch",
      undo: "/match/undo",
      switchbats: "/match/change_batsman",
      createSuperOver: "/match/create_super_over",
      endSuperOver: "/match/end_super_over",
      applyDLS: "/match/apply_dls",
    },
    teams: {
      teamList: "admin/over-all-teams",
      teamDetail: "/team/manage/",
      teamStats: "/admin/team_stats",
    },
    PromoCode: {
      Create: "promo-code/admin-create",
      listing: "promo-code/admin-list",
      Update: "/promo-code/manage",
      Delete: "/promo-code/manage",
    },
    Blog: {
      Create: "admin/blog/create",
      Update: "admin/blog/update",
      Delete: "admin/blog/delete",
      Read: "admin/blog/read",
      status: "admin/blog/status_update",
    },
    Faq: {
      Read: "admin/faq_list",
      Create: "admin/faq",
      Update: "admin/faq",
      Delete: "admin/delete_faq",
    },
    Banner: {
      Create: "admin/banner/create",
      Read: "admin/banner/read",
      Update: "admin/banner/update",
      Delete: "admin/banner/delete",
      status: "admin/banner/status_update",
    },
    AppScreen: {
      Create: "admin/create",
      Read: "admin/read",
      Delete: "/admin/delete",
      status: "/admin/status_update",
    },
    Post: {
      Create: "admin/create_new_feed",
      Read: "globle/new_feed_list",
      Delete: "admin/delete_new_feed",
      DeleteComment: "admin/remove_comment",
      CommentReply: "globle/new_reply",
    },
    Poll: {
      Create: "team/add_poll",
      Read: "team/poll_list",
      Delete: "team/delete_poll",
    },
    Badges: {
      Create: "badge/create",
      Read: "badge/read",
      Update: "badge/update",
      Delete: "badge/delete",
    },
    Subscription: {
      Create: "badge/create",
      Read: "badge/read",
      Update: "badge/update",
      Delete: "badge/delete",
    },
    CountryRoutes: {
      CountryList: "/static/country",
      City: "/static/city",
      State: "/static/state",
    },
    Advertisement: {
      Create: "/admin/create_ad",
      Read: "/admin/ad_list",
      Update: "/admin/update_Ad",
      Delete: "/admin/delete_ad",
      DeleteImage: "/admin/delete_image",
    },
    TaxCharges: "/admin/tax_fees",
  },

  Vendor_Dashboard: {
    Card_data: "/auth/vendor_dashboard",
    VednorDashboardData: "/vendor/dashboard-app",
    VednorDashboard: "/vendor/quick_view",
    OverAllDashboardData: "/auth/vendor_dashboard",
  },

  Vendor: {
    Login: "/auth/login",
    Signup: "/auth/register",
    VerifyOTP: "/auth/verify-otp",
    VendorAmenities: "/amenity/vendor",
    VendorRules: "/rule/vendor",
    Spots: "/spots/spot-details",
    Spots_Personal_Detail: "/spots/personal-details",
    AddAminityVendor: "/amenity/vendor/",
    AddRulesVendor: "/rule/vendor/",
    Spots_Business_Detail: "/spots/business-details",
    Spots_Gallery: "/spots/spot-images",
    SingleSpot: "/static/spot-details",
    ResendOTP: "/auth/resend-otp",
    BanksName: "/auth/bank-list",
    BookingDetail: "/spots/vendor-turf",
    BookingInvoice: "/spots/vendor-turf",
    updateBooking: "/spots/update_booking",
    Notification: "/notify/list-message",
    support: "/contact/contact_listing",
    StatusUpdate: "auth/confirm-booking",
    Review: "/review/read",
    // Reviews : "/review/read"
    vendor_profile: "/auth/user-profile",
    managerProfile: "/manager/list",
    scorerProfile: "/scorer/list",
    Reply_Review: "/review/reply",
    TurfProfile: "/spots/spot-details",
    vendorProfile: "/spots/vendor-info",
    SingleTurfProfile: "/spots/spot-details",
    UpdateTurfProfile: "/spots/spot-details",
    Tournament: "/tournament/list",
    AddTournament: "/tournament/register",
    SingleTournament: "/tournament/list",
    UpdateTournament: "/tournament/update",
    PromoCode: "/promo-code/manage",
    Bussiness_Detail: "/spots/spot-details",
    Check_Mobile: "/auth/check_mobile_availbility",
    Edit_Bussiness_Detail: "/spots/business-details",
    Edit_Personal_Detail: "/spots/personal-details",
    UserDetail: "/admin/user-listing",
    // http://192.168.1.18:8005/api/admin/user-listing
    DeleteTurfimages: "/spots/spot-images/",
    TurfList: "/spots/spot-details",
    DeleteTurf: "/spots/delete-spot",
    SpecificDate: "/spots/time-cost-date-specific",
    edit_profileimg: "/auth/user-profile",
    SchedulAndCost: {
      fetchTimeAndCost: "/spots/time-cost",
      Create: "/spots/time-cost",
      Merge: "/spots/time-cost/",
    },
    SpecialDay: "/spots/special-cost",
    BlogList: "/admin/blog/read",
    Notification_Message: "/notify/list-notifications",
    Create_Mail: "/support/send_mail_support",
    Mail_List: "/support/mail_listing",
    Transtion: "/spots/payment-history",

    Mail_Reply: "/support/reply_mail",
    Kyc: {
      PostKyc: "/auth/kyc_document_upload",
      UpdateKyc: "/auth/update_kyc",
      GetKyc: "/auth/single_kyc",
      verifyPAN: "/auth/verify_pan",
      generateOtpAadhar: "/auth/generateOtpAadhar",
      verifyAdharOtp: "/auth/verifyOtp",
    },
    OfflineBooking: "/auth/slot_booking",
    Occupancy: "/vendor/slot_availibity",
    previousSlots: "/auth/amend_booking",
    HolidaySlots: "/spots/special-cost",

    UpdateSlotsList: "/spots/slot_detail",
    revenue: "/spots/payment-history",
    // http://192.168.1.8:8005/api/vendor/slot_availibity?spot_id
    markBooked: "/auth/bookmark_slot",
    markAvailable: "/auth/remove_bookmark_slot",
    tournament: {
      tournamentAdminList: "/user/tournament/admin_list",
      scorerList: "/user/tournament/scorer_list",
      assignTournamentAdmin: "/user/tournament/assign_admin",
      assignScorer: "/user/tournament/assign_scorer",
      removeTournamentAdmin: "/user/tournament/remove_admin",
      removeScorer: "/user/tournament/remove_scorer",
      tournamentStats: "/admin/overall_stats",
    },
  },
  User: {
    UpcomingTournament: "/globle/upcoming-tournament",
    NearByYouTurf: "/globle/near_to_you_turf",
    PopulerTurf: "/globle/popular_turf",
    TurfAndGround: "/globle/turf-ground",
    Greate_Offer: "/globle/great-offers",
  },
};
export default constant;
