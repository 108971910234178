import ChildBanner from "app/components/web/ChildBanner";

import React from "react";

const TermAndCondition = () => {
  return (
    <>
      <div className="about-banner">
        <div className="container">
          <div className="section_title">
            <span className="title_badge mb-1">Terms And Conditions</span>
            <h2>Play Fair, Stay Informed</h2>
            <p>
              Welcome to Cricksage! By using our platform, you agree to our
              terms designed to ensure a <br /> fair, secure, and seamless
              experience for every cricketer. Stay informed, play responsibly,
              and <br /> enjoy the game!
            </p>
          </div>
        </div>
      </div>
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="policy-content">
                <div className="policy-box">
                  <h6>1. Introduction</h6>
                  <ul>
                    <li>
                      <p>
                        These Terms of Service ("Terms") govern your use of the
                        cricksage.com website/Mobile App and services. By using
                        our website/Mobile App and services, you agree to these
                        Terms and our Privacy Policy. If you do not agree to
                        these Terms, do not use our website/Mobile App and
                        services.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>2. Account Registration</h6>
                  <ul>
                    <li>
                      <p>
                        To use our services, you must register an account with
                        us. You must provide accurate and complete information
                        when registering an account, and you must keep your
                        account information up-to-date.
                      </p>
                    </li>
                    <li>
                      <p>
                        You are responsible for maintaining the confidentiality
                        and security of your account, including your login
                        credentials and any activity that occurs under your
                        account. You agree to notify us immediately if you
                        suspect any unauthorized use of your account.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>3. Booking and Payment</h6>
                  <ul>
                    <li>
                      <p>
                        You may book a cricket ground through our website/Mobile
                        App by selecting the date, time, and location of the
                        ground. We will send you a confirmation email once your
                        booking is complete.
                      </p>
                    </li>
                    <li>
                      <p>
                        You must make payment for your booking in advance
                        through our website/Mobile App. We accept payment
                        through credit/debit cards, UPI, and net banking. We use
                        a third-party payment processor to process your payment,
                        and we do not store your payment information on our
                        servers.
                      </p>
                    </li>
                    <li>
                      <p>
                        If you cancel your booking, we will refund your payment
                        according to our cancellation policy. If you fail to
                        show up for your booking, you will not be entitled to a
                        refund.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="policy-box">
                  <h6>4. Use of Services</h6>
                  <ul>
                    <li>
                      <p>
                        You may use our services only for lawful purposes and in
                        compliance with these Terms and applicable laws and
                        regulations. You may not use our services to engage in
                        any illegal or fraudulent activities or activities that
                        may cause harm to others.
                      </p>
                    </li>
                    <li>
                      <p>
                        You may not copy, reproduce, distribute, or modify any
                        content on our website/Mobile App without our prior
                        written consent. You may not use any automated tools or
                        software to access or use our website/Mobile App, or to
                        collect data from our website/Mobile App.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="policy-box">
                  <h6>5. Intellectual Property Rights</h6>
                  <ul>
                    <li>
                      <p>
                        We own all intellectual property rights in our
                        website/Mobile App and services, including our logo,
                        trademarks, and copyrighted content. You may not use our
                        intellectual property without our prior written consent.
                      </p>
                    </li>
                    <li>
                      <p>
                        You grant us a non-exclusive, royalty-free, worldwide,
                        transferable, sub-licensable licence to use, reproduce,
                        distribute, and modify any content you upload or submit
                        to our website/Mobile App, for the purpose of providing
                        and promoting our services.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>6. Termination</h6>
                  <ul>
                    <li>
                      <p>
                        We may terminate or suspend your account and access to
                        our services at any time, without prior notice, for any
                        reason or no reason. If we terminate your account, you
                        will not be entitled to a refund of any payment you have
                        made to us.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>7. Limitation of Liability</h6>
                  <ul>
                    <li>
                      <p>
                        We are not liable for any damages or losses arising from
                        your use of our website/Mobile App and services,
                        including but not limited to direct, indirect,
                        incidental, punitive, and consequential damages. We are
                        not liable for any errors, omissions, or inaccuracies in
                        our website/Mobile App and services.
                      </p>
                      <li>
                        <p>
                          Our total liability to you for any claims arising from
                          your use of our website/Mobile App and services is
                          limited to the amount you have paid to us for the
                          services.
                        </p>
                      </li>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>8. Governing Law and Dispute Resolution</h6>
                  <ul>
                    <li>
                      <p>
                        These Terms are governed by the laws of India, without
                        regard to its conflict of law provisions. Any dispute
                        arising from these Terms or your use of our
                        website/Mobile App and services will be resolved through
                        arbitration in accordance with the Indian Arbitration
                        and Conciliation Act, 1996.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>9. Changes to Terms</h6>
                  <ul>
                    <li>
                      <p>
                        We reserve the right to modify or update these Terms at
                        any time without prior notice. The revised Terms will be
                        effective from the date of publication on our
                        website/Mobile App. We encourage you to review these
                        Terms periodically to stay informed about our policies.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="policy-box">
                  <h6>10. Booking and Payment: </h6>
                  <ul>
                    <li>
                      <p>
                        The user must provide accurate and complete information
                        when booking a cricket ground or service. The user must
                        pay the full amount of the booking fee, including any
                        taxes or additional charges, at the time of booking.
                        Failure to pay the full amount may result in the
                        cancellation of the booking.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>11. Cancellation and Refunds:</h6>
                  <ul>
                    <li>
                      <p>
                        Please ensure that you carefully review the booking
                        details before confirming your bookings. CRICKSAGE
                        refund process takes into account the variance between
                        the slot's date and time and the current server's date
                        and time. It's important to note that the refund policy
                        is applicable only to slots scheduled for a future date
                        and not to slots that have already passed the current
                        server date and time. The refund amount will be
                        determined according to the following guidelines:
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>Refund Amount (% of Total Booking Amount) </p>
                    </li>
                    <li>
                      <p>Before 24 Hours: 95% Refund</p>
                    </li>
                    <li>
                      <p>12-24 Hours: 75% Refund</p>
                    </li>
                    <li>
                      <p>4-8 Hours: 50% Refund</p>
                    </li>
                    <li>
                      <p>2-4 Hours: 25% Refund</p>
                    </li>
                    <li>
                      <p>0-2 Hours: No Refund</p>
                    </li>
                  </ul>
                  <h6>
                    Note: The refund amount will be calculated based on the SLOT
                    TIMINGS and it will be credited within 15 working days
                  </h6>
                </div>

                <div className="policy-box">
                  <h6>12. Use of Facilities: </h6>
                  <ul>
                    <li>
                      <p>
                        The user must follow all rules and regulations of the
                        cricket ground owner or service provider when using the
                        facilities. The user must respect other users and avoid
                        any behavior that may cause damage, injury, or
                        disturbance to the facilities or other users. The user
                        may be held liable for any damage caused to the
                        facilities due to their negligence or misconduct.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>13. Liability and Insurance: </h6>
                  <ul>
                    <li>
                      <p>
                        The user must assume all risks and liabilities
                        associated with using the cricket ground or service. The
                        cricket ground owner or service provider shall not be
                        liable for any injury, loss, or damage suffered by the
                        user, unless caused by the negligence or misconduct of
                        the cricket ground owner or service provider. The user
                        is advised to obtain their own insurance coverage for
                        any personal or property damage that may occur during
                        their use of the facilities.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="policy-box">
                  <h6>14. Intellectual Property:</h6>
                  <ul>
                    <li>
                      <p>
                        The cricket ground booking website/Mobile App and all
                        its content, including text, images, logos, and
                        trademarks, are the property of the website/Mobile App
                        owner or its licensors. The user may not reproduce,
                        distribute, or modify any content on the website/Mobile
                        App without the prior written consent of the
                        website/Mobile App owner.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>15. Privacy Policy:</h6>
                  <ul>
                    <li>
                      <p>
                        The user's personal information, including their name,
                        contact details, and payment information, will be
                        collected and used in accordance with the website/Mobile
                        App's privacy policy. The user is advised to read and
                        understand the privacy policy before providing any
                        personal information to the website/Mobile App.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="policy-box">
                  <h6>16. Governing Law:</h6>
                  <ul>
                    <li>
                      <p>
                        These terms and conditions shall be governed by and
                        construed in accordance with the laws of India. Any
                        disputes arising from or in connection with these terms
                        and conditions shall be subject to the exclusive
                        jurisdiction of the courts of India.
                      </p>
                      <p>
                        By using the cricket ground booking website/Mobile App,
                        the user agrees to these terms and conditions and
                        acknowledges that they have read and understood them.
                      </p>
                      <p>
                        The website/ Mobile App owner reserves the right to
                        modify or update these terms and conditions at any time
                        without prior notice.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermAndCondition;
