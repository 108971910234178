import { createSlice } from "@reduxjs/toolkit";

export const SignupOtp = createSlice({
  name: "Otp",
  initialState: {
    otp: "",
  },
  reducers: {
    setSignpotp: (state, action) => {
      state.otp = action.payload;
    },
  },
});

export const { setSignpotp } = SignupOtp.actions;

export default SignupOtp.reducer;
