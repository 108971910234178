import { Card } from "@mui/material";
import { Box, styled } from "@mui/system";

const CardRoot = styled(Card)(() => ({
  height: "100%",
  padding: "20px 24px 20px 24px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
}));

const CardTitle = styled("div")(({ subtitle }) => ({
  fontSize: "1.5rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

const SimpleCard = ({ children, title, subtitle, icon }) => {
  return (
    <CardRoot elevation={6}>
      <CardTitle subtitle={subtitle}>{title}</CardTitle>
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
