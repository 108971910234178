import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const BadgesList = Loadable(lazy(() => import("./BadgesList")));

const BadgesRoutes = [
  {
    path: "/badges-list",
    element: <BadgesList />,
    auth: authRoles.guest,
  },
];

export default BadgesRoutes;
