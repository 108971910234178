import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { setProfileData } from "app/redux/reducers/ProfileData";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addBusinessDetail, getBanksName } from "services/web/Turf";
import * as Yup from "yup";

const initialValues = {
  registration_id: "",
  gstin: "",
  ifsc_number: "",
  bank_name: "",
  account_name: "",
  account_number: "",
  upi_id: "",
};
export default function AccountDetail() {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [businessDetail, setBusinessDetail] = useState([]);
  const [bankname, setBankname] = useState([]);

  const navigate = useNavigate();
  // const textStyle = {
  //   style: {
  //     height:'10px'
  //   }
  // }

  const Data = useSelector((state) => state.setProfileData.data);

  const validationSchema = Yup.object().shape({
    bank_name: Yup.string().required("Bank name is required"),
    account_name: Yup.string().required("Account holder name is required"),
    account_number: Yup.string()
      .min(9, "Please enter valid account number")
      .max(18, "Please enter valid account number")
      .matches("^[0-9]", "Please enter valid account number")
      .required("Account number is required"),
    ifsc_number: Yup.string()
      .matches("^[A-Z]{4}0[A-Z0-9]{6}$", "Please enter valid ifsc number")
      .required("IFSC number is required"),
    upi_id: Yup.string()
      .matches(
        /^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/,
        "Please enter correct upi id"
      )
      .required("UPI ID is required"),
    gstin: Yup.string().matches(
      "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
      "Please enter valid GST number"
    ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      await addBusinessDetail(values)
        .then((res) => {
          setLoader(true);
          setBusinessDetail(res.data);
          let profileData = { ...Data };
          profileData["business_details"] = res?.data?.business_details;
          dispatch(setProfileData(profileData));
          toast.success(res?.message);
          navigate("/vendor-dashboard");

          // nextStep();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });

  const getBank = async () => {
    await getBanksName()
      .then((res) => {
        setBankname(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getBank();
  }, []);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <div className="content-body rightside-event main-dash">
      <div className="container-fluid">
        <div
          className="details p-4"
          style={{ backgroundColor: "white", borderRadius: "10px" }}
        >
          <div className="heading mb-3">
            <h4 className="vendor-h4">Account Details </h4>
          </div>
          <form
            onSubmit={handleSubmit}
            className="bussiness-add"
            id="edit-ground"
          >
            <div className="row">
              <div className="col-md-4 mb-4">
                <InputLabel
                  htmlFor="my-input"
                  className="vendor-input-label mb-1 ms-1"
                >
                  Business Registration ID
                </InputLabel>
                <TextField
                  type="text"
                  fullWidth
                  name="registration_id"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your registration ID"
                  // inputProps={textStyle}
                  value={values?.registration_id}
                  {...getFieldProps("registration_id")}
                  helperText={touched.registration_id && errors.registration_id}
                  error={Boolean(
                    errors.registration_id && touched.registration_id
                  )}
                />
              </div>

              <div className="col-md-4 mb-4">
                <InputLabel
                  htmlFor="my-input"
                  className="vendor-input-label mb-1 ms-1"
                >
                  GSTIN
                </InputLabel>
                <TextField
                  type="text"
                  fullWidth
                  name="gstin"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your GSTIN "
                  value={values?.gstin}
                  {...getFieldProps("gstin")}
                  helperText={touched.gstin && errors.gstin}
                  error={Boolean(errors.gstin && touched.gstin)}
                />
              </div>

              <div className="col-md-4 mb-4">
                <InputLabel
                  htmlFor="my-input"
                  className="vendor-input-label mb-1 ms-1"
                >
                  Account Holder’s Name*
                </InputLabel>
                <TextField
                  type="text"
                  fullWidth
                  name="account_name"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter the account holder’s name"
                  value={values?.account_name}
                  {...getFieldProps("account_name")}
                  helperText={touched.account_name && errors.account_name}
                  error={Boolean(errors.account_name && touched.account_name)}
                />
              </div>

              <div className="col-md-4 mb-4 bank_input">
                <div className="form-group">
                  <InputLabel
                    htmlFor="my-input"
                    className="vendor-input-label mb-1 ms-1"
                  >
                    Bank Name*
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.bank_name && touched.bank_name)}
                  >
                    <Select
                      fullWidth
                      className="turf-amenity-select"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="bank_name"
                      displayEmpty
                      placeholder="Bank name"
                      value={values?.bank_name}
                      onChange={(e) =>
                        setFieldValue("bank_name", e.target.value)
                      }
                    >
                      <MenuItem value="">Select Bank</MenuItem>
                      {bankname &&
                        bankname.length > 0 &&
                        bankname.map((item, i) => (
                          <MenuItem key={i} value={item.bank_name}>
                            {item.bank_name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {touched.bank_name && errors.bank_name}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <InputLabel
                  htmlFor="my-input"
                  className="vendor-input-label mb-1 ms-1"
                >
                  Account Number*
                </InputLabel>

                <TextField
                  type="tel"
                  fullWidth
                  name="account_number"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter the bank account number"
                  value={values?.account_number}
                  {...getFieldProps("account_number")}
                  helperText={touched.account_number && errors.account_number}
                  error={Boolean(
                    errors.account_number && touched.account_number
                  )}
                />
              </div>

              <div className="col-md-4 mb-4">
                <InputLabel
                  htmlFor="my-input"
                  className="vendor-input-label mb-1 ms-1"
                >
                  IFSC*
                </InputLabel>
                <TextField
                  type="text"
                  fullWidth
                  name="ifsc_number"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your IFSC"
                  value={values?.ifsc_number}
                  {...getFieldProps("ifsc_number")}
                  helperText={touched.ifsc_number && errors.ifsc_number}
                  error={Boolean(errors.ifsc_number && touched.ifsc_number)}
                />
              </div>

              <div className="col-md-4 mb-4">
                <InputLabel
                  htmlFor="my-input"
                  className="vendor-input-label mb-1 ms-1"
                >
                  UPI Details*
                </InputLabel>
                <TextField
                  type="text"
                  fullWidth
                  name="upi_id"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter the UPI Details "
                  value={values?.upi_id}
                  {...getFieldProps("upi_id")}
                  helperText={touched.upi_id && errors.upi_id}
                  error={Boolean(errors.upi_id && touched.upi_id)}
                />
              </div>

              <Grid item lg={6} md={3} sm={12} xs={12} sx={{ mt: 3, mb: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className="p-2"
                  style={{
                    backgroundColor: "#333",
                    color: "white",
                    width: "10%",
                  }}
                >
                  {(loader && <Spinner size="sm" />) || "Save"}
                </Button>
              </Grid>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
