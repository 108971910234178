import {
  _delete,
  _get,
  _getToken,
  _post,
  _putAuth,
  convertObjToQueryStr,
} from "api/Apimethod";
import axios from "axios";
import constant from "constant";

export const getUserList = async (params, navigate) => {
  let url = constant.Admin.UserList;
  let str = convertObjToQueryStr(params);
  url = url + str;
  const response = await _get(url, navigate);
  return response;
};

export const deleteUserData = async (data) => {
  let url = constant.Admin.UserStatus + "/" + data.id;
  const response = await _delete(data);
  return response;
};
//   export const getUserProfile = async  (id)=>{
// 	const url = constant.Admin.UserList+"/"+id;
// 	const response = await _get(url)
// 	return response.data
//   }
export const getuser = async (id) => {
  let url = constant.Admin.SingleUser + "/" + id;
  const response = await _get(url);
  return response;
};

export const ActiveUser = async (data) => {
  const url = constant.Admin.UserStatus1 + "/" + data.id;
  const response = await _putAuth(url, data);
  return response;
};
export const getUser = async (id) => {
  const url = constant.Admin.UserList + "/" + id;
  const response = await _get(url);
  return response;
};

export const Add_User = async (data, values) => {
  const response = await _post(constant.Admin.UserStatus, data, values);
  return response;
};

export const deleteUser = async (id) => {
  const data = await _delete(constant.Admin.UserStatus, id);
  return data;
};

export const patchUser = async (values) => {
  const response = await axios._putAuth(`/user`, values, {
    headers: { Authorization: `${_getToken()}` },
  });

  return response.data;
};
export const getsearchUser = async (params, search) => {
  const response = await _get(constant.Admin.UserList);

  return response.data;
};
export const gettypeUser = async (value) => {
  const response = await _get(`/admin/search_paid_unpaid?user_type=${value}`);
  return response.data;
};
