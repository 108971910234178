import { createSlice } from "@reduxjs/toolkit";

export const OfflineBooking = createSlice({
  name: "Otp",
  initialState: {
    data: "",
  },
  reducers: {
    setOfflineData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setOfflineData } = OfflineBooking.actions;

export default OfflineBooking.reducer;
