import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStats } from "services/admin/TeamService";

export default function Stats() {
  const [state, setState] = useState([]);
  const { id } = useParams();
  const getData = async () => {
    let obj = {
      id: id,
    };
    await getStats(obj).then((res) => {
      if (res?.status) {
        setState(res?.data[0]?.team_details[0]);
      }
      console.log(res, "resssssssss");
    });
  };

  console.log(state?.match_played, "resssssss");

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-4">
      <Table
        className="border"
        sx={{ minWidth: 750 }}
        aria-label="simple table"
      >
        <TableHead style={{ backgroundColor: "#222B42" }}>
          <TableRow>
            <TableCell width={"12%"} className="text-white" align="center">
              Matches
            </TableCell>
            <TableCell className="text-white" align="center">
              Win
            </TableCell>
            <TableCell className="text-white" align="center">
              Tennis
            </TableCell>
            <TableCell className="text-white" align="center">
              Leather
            </TableCell>
            <TableCell className="text-white" align="center">
              Win per
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{state?.match_played || "0"}</TableCell>
            <TableCell align="center">{state?.match_won || "-"}</TableCell>
            <TableCell align="center">{state?.tennis || "0"}</TableCell>
            <TableCell align="center">{state?.leather || "0"}</TableCell>
            <TableCell align="center">
              {state?.winning_per + "%" || "0"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
