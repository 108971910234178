const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: "full",
    theme: "slateDark1",
    bgimgurl: "/assets/images/sidebar/sidebar-bg-dark.jpg",
  },
  topbar: {
    show: true,
    fixed: true,
    theme: "whiteBlue",
  },
};

export default Layout1Settings;
