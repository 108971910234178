import { _post, _postAuth } from "api/Apimethod";
import constant from "constant";

export const authSignup = async (data) => {
  let url = constant.Vendor.Signup;
  const response = await _postAuth(url, data);
  return response;
};
export const verifyOTP = async (data) => {
  let url = constant.Vendor.VerifyOTP;
  const response = await _post(url, data);
  return response;
};
export const reSendOTP = async (data) => {
  let url = constant.Vendor.ResendOTP;
  const response = await _post(url, data);
  return response;
};
