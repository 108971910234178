import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { MatxLoading } from "app/components";
export default function Ball({ state, loader }) {
  const formateNumber = (num) => {
    if (Number(num) % 1 !== 0) {
      return parseFloat(Number(num).toFixed(1));
    } else {
      return parseFloat(num);
    }
  };
  return (
    <div>
      {(loader && (
        <div className="text-center mt-2">
          <MatxLoading />
        </div>
      )) ||
        (state?.leather?.bowling || state?.tennis?.bowling ? (
          <div className="mt-4  table-responsive">
            <Table
              className="border"
              sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead style={{ backgroundColor: "#222B42" }}>
                {/* ------------------ Bowling Header ------------- */}

                <TableRow>
                  <TableCell
                    width={"12%"}
                    className="text-white"
                    align="center"
                  >
                    Bowling
                  </TableCell>
                  {/* <TableCell className="text-white" align="center">
                          Matches
                        </TableCell>
                        <TableCell className="text-white" align="center">
                          Innings
                        </TableCell> */}
                  <TableCell className="text-white" align="center">
                    Over
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Maidens
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Wickets
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Run
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    4 Wk
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    5 Wk
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Eco
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Avg
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Wides
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    No Ball
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Dots Ball
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* ------------------ Bowling Overall ------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Overall
                  </TableCell>
                  {/* <TableCell align="center">
                          {state?.tennis?.bowling?.match ||
                            0 + state?.leather?.bowling?.match ||
                            0}
                        </TableCell>
                        <TableCell align="center">
                          {state?.tennis?.bowling?.innings ||
                            0 + state?.leather?.bowling?.innings ||
                            0}
                        </TableCell> */}
                  <TableCell align="center">
                    {/* {state?.tennis?.bowling?.overs_bowled ||
                            0 + state?.leather?.bowling?.overs_bowled ||
                            0} */}
                    {(
                      (state?.tennis?.bowling?.overs_bowled || 0) +
                      (state?.leather?.bowling?.overs_bowled || 0)
                    ).toFixed(1)}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.maidens ||
                      0 + state?.leather?.bowling?.maidens ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.wickets ||
                      0 + state?.leather?.bowling?.wickets ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.runs_conceded ||
                      0 + state?.leather?.bowling?.runs_conceded ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.fourWk ||
                      0 + state?.leather?.bowling?.fourWk ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.fiveWk ||
                      0 + state?.leather?.bowling?.fiveWk ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {/* {Number(bowlereconomy) > 0
                            ? Number(bowlereconomy).toFixed(2)
                            : 0} */}
                    {formateNumber(
                      state?.tennis?.bowling?.eco ||
                        0 + state?.leather?.bowling?.eco ||
                        0
                    )}

                    {/* {state?.tennis?.bowling?.eco ||
                            0 + state?.leather?.bowling?.eco ||
                            0} */}
                  </TableCell>
                  <TableCell align="center">
                    {/* {Number(bowleraverage) > 0
                            ? Number(bowleraverage).toFixed(2)
                            : 0} */}
                    {/* {state?.tennis?.bowling?.avg ||
                            0 + state?.leather?.bowling?.avg ||
                            0} */}
                    {formateNumber(
                      state?.tennis?.bowling?.avg ||
                        0 + state?.leather?.bowling?.avg ||
                        0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.wides ||
                      0 + state?.leather?.bowling?.wides ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.no_ball ||
                      0 + state?.leather?.bowling?.no_ball ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.match ||
                      0 + state?.leather?.bowling?.match ||
                      0}
                  </TableCell>
                </TableRow>

                {/* ------------------ Bowling Leather Ball	------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Leather Ball
                  </TableCell>
                  {/* <TableCell align="center">
                          {state?.leather?.bowling?.match || 0}
                        </TableCell>
                        <TableCell align="center">
                          {state?.leather?.bowling?.innings || 0}
                        </TableCell> */}
                  <TableCell align="center">
                    {state?.leather?.bowling?.overs_bowled || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.maidens || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.wickets || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.runs_conceded || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.fourWk || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.fiveWk || 0}
                  </TableCell>
                  <TableCell align="center">
                    {formateNumber(state?.leather?.bowling?.aco || 0)}
                  </TableCell>
                  <TableCell align="center">
                    {formateNumber(state?.leather?.bowling?.avg || 0)}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.wides || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.no_ball || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.bowling?.match || 0}
                  </TableCell>
                </TableRow>

                {/* ------------------ Bowling Leather Ball	------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Tennis Ball
                  </TableCell>
                  {/* <TableCell align="center">
                          {state?.tennis?.bowling?.match || 0}
                        </TableCell>
                        <TableCell align="center">
                          {state?.tennis?.bowling?.innings || 0}
                        </TableCell> */}
                  <TableCell align="center">
                    {state?.tennis?.bowling?.overs_bowled || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.maidens || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.wickets || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.runs_conceded || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.fourWk || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.fiveWk || 0}
                  </TableCell>
                  <TableCell align="center">
                    {/* {Number(state?.tennis?.bowling?.eco) > 0
                            ? Number(state?.tennis?.bowling?.eco).toFixed(2)
                            : 0} */}
                    {state?.tennis?.bowling?.eco || 0}
                  </TableCell>
                  <TableCell align="center">
                    {/* {Number(state?.tennis?.bowling?.ballAvg) > 0
                            ? Number(state?.tennis?.bowling?.ballAvg).toFixed(2)
                            : 0} */}
                    {/* {state?.tennis?.bowling?.ballAvg || 0} */}
                    {formateNumber(state?.tennis?.bowling?.avg || 0)}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.wides || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.no_ball || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.bowling?.match || 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          ""
        ))}
    </div>
  );
}
