import { Box, styled } from "@mui/system";
import Lottie from "react-lottie";
import animationlogo from "../components/cricketLoader";

const StyledLoading = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& img": {
    width: "auto",
    height: "39px",
  },

  "& .circleProgress": {
    position: "absolute",
    left: 0,
    right: 0,
  },
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationlogo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loading = () => {
  return (
    <StyledLoading>
      <Box position="relative">
        <Lottie options={defaultOptions} height={100} width={100} />
      </Box>
    </StyledLoading>
  );
};

export default Loading;
