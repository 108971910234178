import Loadable from "app/components/Loadable";
import { lazy } from "react";

const BlogPage = Loadable(lazy(() => import("./BlogPage")));
const SingleBlog = Loadable(lazy(() => import("./SingleBlog")));

const blogRouting = [
  { path: "/blog", element: <BlogPage /> },
  { path: "/single-blog/:id", element: <SingleBlog /> },
];
export default blogRouting;
