import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Helper } from "app/helperFuntion";
import { favicon } from "Assets";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { notificationList } from "services/vendor/NotificationService";

export const VendorNotification = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total_record: 0,
  });

  const APIImage = process.env.REACT_APP_IMG_URL;

  const getData = async (page, value) => {
    setLoader(true);
    const params = {
      page: page,
      limit: pagination.limit,
      search: value ? value : "ALL",
    };
    await notificationList(params)
      .then((res) => {
        setLoader(false);
        setNotificationData(res?.data?.data);
        setPage(res?.data?.pagination.page);
        setPagination({
          ...pagination,
          page: page,
          limit: res?.data?.pagination.limit,
          total_record: res?.data?.pagination.total_record,
        });
      })
      .catch((error) => {});
    setLoader(false);
  };

  const handlePageChange = (selectedObject) => {
    setPagination({ ...pagination, page: selectedObject.selected + 1 });
    getData(selectedObject.selected + 1);
  };

  useEffect(() => {
    getData(page);
  }, []);

  return (
    <div className="content-body rightside-event">
      <div className="container-fluid">
        <div className="top-banner main-box Notification-box">
          <div className="d-flex justify-content-between">
            <div className="mb-3">
              <h4 className="vendor-h4">Notification List</h4>
            </div>
          </div>
          <TableContainer
            className="mt-2"
            style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              {(loader && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} className="text-center">
                      <Spinner animation="border" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )) || (
                <TableBody>
                  {notificationData?.length > 0 ? (
                    notificationData &&
                    notificationData.length > 0 &&
                    notificationData.map((item, index) => {
                      if (item.notificaton_type === "BOOKING") {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center" style={{ width: "12%" }}>
                              {(item?.image && (
                                <img
                                  style={{
                                    width: 44,
                                    height: 44,
                                    borderRadius: 22,
                                  }}
                                  src={APIImage + item?.image}
                                  alt="loading"
                                />
                              )) || (
                                <img
                                  style={{
                                    width: 44,
                                    height: 44,
                                    borderRadius: 22,
                                  }}
                                  src={favicon}
                                  alt="loading"
                                />
                              )}
                            </TableCell>
                            <TableCell colSpan={3}>
                              {item?.message || "-"}
                            </TableCell>
                            <TableCell
                              className="text-center"
                              style={{ width: "12%" }}
                            >
                              {Helper.calcRemainingTime(item?.created_at) +
                                " ago"}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan="6" className="text-center">
                        Data Not Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {notificationData?.length > 0 &&
          pagination?.total_record > pagination?.limit ? (
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={pagination?.total_record / pagination?.limit}
              previousLabel="< Previous"
              renderOnZeroPageCount={null}
              containerClassName={"pagination-container"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
