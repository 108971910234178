import Loadable from "app/components/Loadable";
import { lazy } from "react";

const LoginPage = Loadable(lazy(() => import("./LoginPage")));
const LoginOtp = Loadable(lazy(() => import("./LoginOtp")));

const loginRoutes = [
  { path: "/login", element: <LoginPage /> },
  { path: "/login-otp/:phone", element: <LoginOtp /> },
];
export default loginRoutes;
