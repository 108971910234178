import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const RevenueList = Loadable(lazy(() => import("./RevenueList")));

const revenueRoutes = [
  {
    path: "/revenue-list",
    element: <RevenueList />,
    auth: authRoles.guest,
  },
];

export default revenueRoutes;
