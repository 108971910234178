import { styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MatxLoading } from "app/components";
import { DefaultImg } from "Assets";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlayerDetails, getPlayerStats } from "services/admin/Dashboard";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import Ball from "../../Usermanagment/Ball";
import Bat from "../../Usermanagment/Bat";
import Field from "../../Usermanagment/Field";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const PlayerDetail = () => {
  const [dtl, setDtl] = useState([]);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [value, setValue] = useState("1");

  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = async () => {
    let params = { id: id };
    await getPlayerDetails(params)
      .then((res) => {
        setDtl(res?.data?.data);
      })
      .catch((error) => {});
  };

  const getState = async () => {
    setLoader(true);
    let params = { player_id: id.id };
    await getPlayerStats(params)
      .then((res) => {
        setLoader(false);
        setState(res?.data?.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getData();
    getState();
  }, []);

  let APIINITIAL = process.env.REACT_APP_IMG_URL;

  let roleobj = {
    BATTER: "Batter",
    BOWLER: "Bowler",
    ALLROUNDER: "All rounder",
    WICKETKEEPER: "Wicket keeper",
  };

  let batstyleobj = {
    LEFTHAND: "Left Hand",
    RIGHTHAND: "Right Hand",
  };

  let ballstyleobj = {
    LEFTARM: "Left Arm",
    RIGHTARM: "Right Arm",
  };

  return (
    <Container>
      <div style={{ backgroundColor: "#222B42" }}>
        <div>
          <div className="row">
            <div className="col-sm-6 pt-5 pb-4">
              <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                <img
                  style={{
                    width: 300,
                    height: 300,
                    // boxShadow: "inset 0 -20px 12px -6px #222B42",
                    // border: "5px solid white",
                    objectFit: "cover",
                  }}
                  src={(dtl?.image && APIINITIAL + dtl?.image) || DefaultImg}
                  onError={() => DefaultImg}
                  alt="server problem"
                />

                <div className="player-name mt-3 text-white">
                  <h6 className="text-white mb-0">{dtl?.name || "-"}</h6>
                </div>
              </div>
            </div>

            <div className="col-sm-6 pt-md-5 pt-sm-0 pb-4 d-flex justify-content-center pe-md-5">
              <div className="bg-grey w-75 pt-sm-0  pb-5 pt-4 ">
                {/* <h2 className="h2-chart text-white pb-2">Player Overview</h2> */}
                <TableContainer
                  component={Paper}
                  style={{
                    backgroundColor: "#fff0",
                    border: "1px solid white",
                  }}
                >
                  <Table sx={{ minWidth: 200 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.email || "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Phone number
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.phone_number || "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Joining date
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.created_at?.split("T")[0] || "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Playing Role
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {roleobj[dtl?.roll]}
                          {/* {dtl?.roll || "-"} */}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          Jersy number
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          {dtl?.jersey_number || "-"}
                        </TableCell>
                      </TableRow>

                      {(dtl?.batting_style && (
                        <TableRow>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                              fontWeight: "300",
                            }}
                          >
                            Batting Style
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                            }}
                            align="center"
                          >
                            {batstyleobj[dtl?.batting_style]}
                            {/* {dtl?.batting_style || "-"} */}
                          </TableCell>
                        </TableRow>
                      )) ||
                        " "}

                      {(dtl?.bowling_style && (
                        <TableRow>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                              fontWeight: "300",
                            }}
                          >
                            Bowling Style
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid white",
                              color: "white",
                              textAlign: "center",
                            }}
                            align="left"
                          >
                            {ballstyleobj[dtl?.bowling_style]}
                            {/* {dtl?.bowling_style || "-"} */}
                          </TableCell>
                        </TableRow>
                      )) ||
                        " "}

                      {/* <TableRow>
                    <TableCell style={{ borderRight: '1px solid white', color: 'white', textAlign: 'center',  fontWeight:'300' }}>Gender</TableCell>
                    <TableCell style={{ borderRight: '1px solid white', color: 'white', textAlign: 'center' }} align="left">
                      {genderobj[dtl?.gender]}
                    </TableCell>
                  </TableRow> */}
                      <TableRow>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "300",
                          }}
                        >
                          City
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid white",
                            color: "white",
                            textAlign: "center",
                          }}
                          align="left"
                        >
                          {dtl?.city || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(loader && (
        <div className="text-center mt-2">
          <MatxLoading />
        </div>
      )) || (
        <Box sx={{ width: "100%", typography: "body1" }} className="mt-2">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                TabIndicatorProps={{
                  sx: {
                    border: "1px solid black",
                  },
                }}
                sx={{
                  "& .MuiTab-root": {
                    fontFamily: "Oswald",
                  },
                  ".Mui-selected": {
                    color: `#171717 !important`,
                  },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Batting Stats" value="1" />
                <Tab label="Bowling Stats" value="2" />
                <Tab label="Fielding Stats" value="3" />
              </TabList>
            </Box>

            <TabPanel value="1" className="p-0">
              <Bat state={state} loader={loader} />
            </TabPanel>

            <TabPanel value="2" className="p-0">
              <Ball state={state} loader={loader} />
            </TabPanel>
            <TabPanel value="3" className="p-0">
              <Field state={state} loader={loader} />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </Container>
  );
};

export default PlayerDetail;
