import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { MatxLoading } from "app/components";

export default function Field({ state, loader }) {
  return (
    <div>
      {(loader && (
        <div className="text-center mt-2">
          <MatxLoading />
        </div>
      )) ||
        (state?.leather?.fielding || state?.tennis?.fielding ? (
          <div className="mt-4  table-responsive">
            <Table
              className="border"
              sx={{ minWidth: 750 }}
              aria-label="simple table"
            >
              <TableHead style={{ backgroundColor: "#222B42" }}>
                {/* ------------------ Fielding Header ------------- */}

                <TableRow>
                  <TableCell
                    width={"12%"}
                    className="text-white"
                    align="center"
                  >
                    Fielding
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Catches
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Caught Behind
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Caught Bowled
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Stumping
                  </TableCell>
                  <TableCell className="text-white" align="center">
                    Run Out
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* ------------------ Fielding Overall ------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Overall
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.catches ||
                      0 + state?.leather?.fielding?.catches ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.caught_behind ||
                      0 + state?.leather?.fielding?.caught_behind ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.caught_bowled ||
                      0 + state?.leather?.fielding?.caught_bowled ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.stumping ||
                      0 + state?.leather?.fielding?.stumping ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.run_out ||
                      0 + state?.leather?.fielding?.run_out ||
                      0}
                  </TableCell>
                </TableRow>

                {/* ------------------ Fielding Leather Ball	------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Leather Ball
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.fielding?.catches || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.fielding?.caught_behind || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.fielding?.caught_bowled || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.fielding?.stumping || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.leather?.fielding?.run_out || 0}
                  </TableCell>
                </TableRow>

                {/* ------------------ Fielding Tennis Ball	------------- */}

                <TableRow>
                  <TableCell
                    style={{ color: "#222B42" }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    Tennis Ball
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.catches || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.caught_behind || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.caught_bowled || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.stumping || 0}
                  </TableCell>
                  <TableCell align="center">
                    {state?.tennis?.fielding?.run_out || 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          ""
        ))}
    </div>
  );
}
