import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const TounamentListPage = Loadable(lazy(() => import("./TounamentListPage")));
const AddSponsors = Loadable(lazy(() => import("./AddSponsors")));

const AddTournament = Loadable(lazy(() => import("./AddTournament")));
const EditTournament = Loadable(lazy(() => import("./EditTournament")));
const MatchDetail = Loadable(lazy(() => import("./StartMatch/MatchDetail")));

const TournamentDetail = Loadable(lazy(() => import("./TournamentDetail")));
const AddTeam = Loadable(lazy(() => import("./Team/AddTeam")));

const tournamentRoutes = [
  {
    path: "/tournament/:added_by",
    element: <TounamentListPage />,
    auth: authRoles.guest,
  },
  {
    path: "/tournament/:added_by/add",
    element: <AddTournament />,
    auth: authRoles.guest,
  },
  {
    path: "/tournament/sponsors/:id",
    element: <AddSponsors />,
    auth: authRoles.guest,
  },
  {
    path: "/tournament/:added_by/edit/:id",
    element: <EditTournament />,
    auth: authRoles.guest,
  },
  {
    path: "/tournament/:added_by/tournament-detail/:id",
    element: <TournamentDetail />,
    auth: authRoles.guest,
  },
  {
    path: "/tournament/match-detail",
    element: <MatchDetail />,
    auth: authRoles.guest,
  },
  {
    path: "/tournament/add-team",
    element: <AddTeam />,
    auth: authRoles.guest,
  },
];

export default tournamentRoutes;
