import styled from "@emotion/styled";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import { DefaultImg } from "Assets";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTeamsDetail } from "services/admin/TeamService";
import Matches from "./Matches";
import Stats from "./Stats";
import TeamMember from "./TeamMember";
// import SocialFeed from "./SocialFeed";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const TeamDetail = () => {
  const [teamDetails, setTeamDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("member");
  const { id } = useParams();
  const APIINITIAL = process.env.REACT_APP_IMG_URL;

  const getData = async () => {
    setLoader(true);
    await getTeamsDetail(id)
      .then((res) => {
        setLoader(false);
        setTeamDetails(res?.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <div style={{ backgroundColor: "#222B42" }} className="mb-4">
        <div className="row">
          <div className="col-sm-6 pt-5 pb-4">
            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
              <img
                style={{
                  width: 300,
                  height: 300,
                  boxShadow: "inset 0 -20px 12px -6px #222B42",
                  border: "5px solid white",
                  objectFit: "cover",
                }}
                src={
                  teamDetails[0]?.team_logo
                    ? APIINITIAL + teamDetails[0].team_logo
                    : DefaultImg
                }
                onError={() => DefaultImg}
                alt="server problem"
              />
              <div className="player-name mt-3 text-white">
                <h6 className="text-white mb-0">
                  {teamDetails[0]?.team_name || "-"}
                </h6>
              </div>

              <p className="text-white mb-0 mt-3">
                Caption Name : {teamDetails[0]?.captain_name || "-"}{" "}
                <span className="ps-3">
                  {" "}
                  City: {teamDetails[0]?.city || "-"}
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 2,
              borderColor: "divider",
              backgroundColor: "#fff",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="dark"
              TabIndicatorProps={{
                sx: {
                  border: "2px solid #222b42",
                  textAlign: "center",
                },
              }}
              sx={{
                "& .MuiTab-root": {
                  color: "black",
                  fontWeight: "bold",
                },
              }}
            >
              <Tab label="Member" value="member" />
              <Tab label="Matches" value="matches" />
              <Tab label="Stats" value="stats" />
              <Tab label="Social feed" value="feed" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: "25px 0" }} value="member">
            <TeamMember teamDetails={teamDetails} loader={loader} />
          </TabPanel>
          <TabPanel value="matches">
            <Matches />{" "}
          </TabPanel>
          <TabPanel value="stats">
            {" "}
            <Stats />
          </TabPanel>
          <TabPanel value="feed">feed</TabPanel>
        </TabContext>
      </div>
    </Container>
  );
};

export default TeamDetail;
