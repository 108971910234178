import { _get, convertObjToQueryStr } from "api/Apimethod";
import constant from "constant";

export const notificationList = async (params) => {
  let url = constant.Vendor.Notification;
  let str = convertObjToQueryStr(params);
  url = url + str;
  const response = await _get(url);
  return response;
};
export const support = async () => {
  let url = constant.Vendor.support;
  const response = await _get(url);
  return response;
};
